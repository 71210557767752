import React from 'react';
import PropTypes from 'prop-types';
import style from './style.css';

export default class BarGraph extends React.Component {
    static propTypes = {
        metrics: PropTypes.array,
        categories: PropTypes.array,
        flowType: PropTypes.string,
    };

    barColor = score => {
        if (typeof score === 'object' || Number.isNaN(score)) {
            return style.barDefault;
        }

        if (['careSchool', 'careDistrict', 'careEntity'].includes(this.props.flowType)) {
            if (score < 2) {
                return style.barGray;
            }
            if (score < 3) {
                return style.barRed;
            }
            if (score < 5) {
                return style.barYellow;
            }
            if (score <= 6) {
                return style.barGreen;
            }
            return style.barDefault;
        }
        if (this.props.flowType && this.props.flowType.includes('mhq')) {
            if (score < 1) {
                return style.barGray;
            }
            if (score < 3) {
                return style.barRed;
            }
            if (score < 5) {
                return style.barYellow;
            }
            if (score >= 5) {
                return style.barGreen;
            }
        }

        if (score <= 2) {
            return style.barRed;
        }
        if (score > 2 && score <= 4) {
            return style.barYellow;
        }
        if (score >= 4) {
            return style.barGreen;
        }
        return style.scoreZero;
    };

    getBarText = score => {
        if (typeof score === 'object' || Number.isNaN(score)) {
            return `Don't know`;
        }
        if (!Number.isFinite(score)) {
            return '';
        }

        return score;
    };

    render() {
        const { metrics, categories } = this.props;
        const max = 6;
        //    console.log('metrics', metrics, 'categories', categories);
        return (
            <div className={style.barGraph}>
                <div className={style.barScale}>
                    <div className={style.scaleTop}>
                        <div className={style.scaleTopLeft} />
                        <div className={style.scaleTopRight} />
                    </div>
                    <div className={style.scaleBottom} />
                </div>
                {categories && categories.length > 0
                    ? categories.map(category => {
                          const cg = metrics.filter(metric => metric.category === category.handle);
                          return (
                              <div key={category.title}>
                                  <div className={style.categoryTitle}>{category.title}</div>
                                  {cg &&
                                      cg.map(obj => {
                                          return (
                                              <div style={{ display: 'flex' }} key={obj.title}>
                                                  <div className={style.barBg}>
                                                      <div
                                                          className={style.score + ' ' + this.barColor(obj.score)}
                                                          style={{
                                                              width: `${(obj.score / max) * 100}%`,
                                                          }}
                                                      >
                                                          {this.getBarText(obj.score)}
                                                      </div>
                                                  </div>
                                                  <div className={style.description}>
                                                      <p>{obj.title}</p>
                                                  </div>
                                              </div>
                                          );
                                      })}
                              </div>
                          );
                      })
                    : metrics.map(metric => (
                          <div style={{ display: 'flex' }} key={metric.title}>
                              <div className={style.barBg}>
                                  <div
                                      className={style.score + ' ' + this.barColor(metric.score)}
                                      style={{
                                          width: `${(metric.score / max) * 100}%`,
                                      }}
                                  >
                                      {this.getBarText(metric.score)}
                                  </div>
                              </div>
                              <div className={style.description}>
                                  <p>{metric.title}</p>
                              </div>
                          </div>
                      ))}
            </div>
        );
    }
}
