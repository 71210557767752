import React from 'react';
import { Route, NotFound } from 'cccisd-laravel-boilerplate';
import defaultProps from 'recompose/defaultProps.js';
import { Switch } from 'react-router-dom';
import { Dashboard as AppdefDashboard, Manage as AppdefManage, AdminRoutes } from 'cccisd-laravel-appdefs';
import { Quest } from 'cccisd-laravel-assignment';
import { ResourceCenter } from 'cccisd-laravel-resources';

// Layouts
import MainLayout from './layouts/MainLayout';
import NavOnlyLayout from './layouts/NavOnlyLayout';
import BigContainerLayout from './layouts/BigContainerLayout';

// Pages
/* eslint-disable import/no-extraneous-dependencies, import/no-unresolved */
import TRSFeedback from './pages/TRSFeedback'; // flow preview
import AnonymousAssessment from './pages/AnonymousAssessment';
import NavOnly from './pages/NavOnly'; // iframe for wordpress site
import ScreeningAndAssessment from 'bundle-loader?lazy!./pages/ScreeningAndAssessment';
import TraumaResponsiveness from 'bundle-loader?lazy!./pages/TraumaResponsiveness';
import OWBI from 'bundle-loader?lazy!./pages/OWBI';
import CARE from 'bundle-loader?lazy!./pages/CARE';
import MentalHealth from 'bundle-loader?lazy!./pages/MentalHealth';
import ExportData from 'bundle-loader?lazy!./pages/ExportData';
import RolePicker from 'bundle-loader?lazy!./pages/RolePicker';
import SignUp from 'bundle-loader?lazy!./pages/SignUp';
import SiteContent from 'bundle-loader?lazy!./components/SiteContent';
import JoinATeam from 'bundle-loader?lazy!./pages/JoinATeam';
import Overview from 'bundle-loader?lazy!./pages/Overview';
import MHProfile from 'bundle-loader?lazy!./pages/MHProfile';
import EntityMyDistricts from 'bundle-loader?lazy!./pages/EntityMyDistricts';
import EntityMySchools from 'bundle-loader?lazy!./pages/EntityMySchools';

// preschool assessment
import QualityAssessment from 'bundle-loader?lazy!./pages/QualityAssessment';

// Components
import AuthRedirect from 'bundle-loader?lazy!./components/AuthRedirect';
import Manage from 'bundle-loader?lazy!./components/Manage';
import DescendantGroupDash from 'bundle-loader?lazy!./components/DescendantGroupDash';
import UpdateProfile from 'bundle-loader?lazy!./components/UpdateProfile';

// Laravel packages
import Nexus from 'bundle-loader?lazy!cccisd-laravel-nexus';
import Resources from 'bundle-loader?lazy!cccisd-laravel-resources';

// Bind MainLayout by default
const AppRoute = defaultProps({ layout: MainLayout })(Route);
const MainLayoutFluid = defaultProps({ className: 'container-fluid' })(MainLayout);

var AppDefinitions = window.cccisd.appDefs;
var Fortress = window.cccisd.fortress;
const actingUser = Fortress.user.acting;

const match = {
    AppdefManage,
    AppdefDashboard,
    TRSFeedback,
    AnonymousAssessment,
    TraumaResponsiveness,
    ResourceCenter,
    MentalHealth,
    ScreeningAndAssessment,
    RolePicker,
    JoinATeam,
    Manage,
    DescendantGroupDash,
    Overview,
    MHProfile,
    EntityMyDistricts,
    EntityMySchools,
    QualityAssessment,
    OWBI,
    CARE,
};

const layoutMatch = { FluidLayout: MainLayoutFluid, BigContainerLayout };

const getRolePickerLayout = () => {
    const userGroup = actingUser.group;
    if (userGroup) {
        if (userGroup.data_type === 'entity') {
            return BigContainerLayout;
        }
    }
    if (actingUser.data_type === 'uberadmin') {
        return MainLayoutFluid;
    }
    return MainLayout;
};

export default () => (
    <Switch>
        {AppDefinitions.routes.map(route => {
            const Component = match[route.componentHandle];
            if (!Component) {
                return null;
            }

            return (
                <AppRoute
                    key={route.handle}
                    path={route.url}
                    component={Component}
                    componentProps={route.componentProps}
                    exact
                    layout={'layout' in route ? layoutMatch[route.layout] : MainLayoutFluid}
                />
            );
        })}

        <AppRoute path={['/quest/preview', '/d', '/survey']} component={Quest} layout={null} />

        <AppRoute path="/" component={AuthRedirect} exact />

        {Fortress.auth() && <AppRoute path="/rolePicker" component={RolePicker} layout={getRolePickerLayout()} />}

        <AppRoute path="/signUp" component={SignUp} />

        {Fortress.hasRole('uberadmin') && (
            <AppRoute path="/siteContent" component={SiteContent} layout={MainLayoutFluid} />
        )}

        {Fortress.hasRole('uberadmin') && (
            <AppRoute path="/exportData" component={ExportData} layout={MainLayoutFluid} />
        )}

        {Fortress.auth() && <AppRoute path="/trsFeedback" component={TRSFeedback} />}
        <AppRoute path="/anonSurvey/:flowType/:groupId/:respondentHash?" component={AnonymousAssessment} />

        <AppRoute path="/shapeNav" component={NavOnly} exact layout={NavOnlyLayout} />

        <Route path="/account/profile/:pawnId?/:pawnHash?" component={UpdateProfile} layout={BigContainerLayout} />

        {/* Laravel packages routes */}
        {['uberadmin'].includes(Fortress.user.acting.data_type) && AdminRoutes(MainLayoutFluid)}

        {/* Show Resource Admin for Uberadmins */}
        {Fortress.hasRole('uberadmin') && <AppRoute path="/resources" component={Resources} />}

        {Fortress.auth() && (
            <AppRoute
                path="/learnAndShareShape/:tab?/:id?"
                component={ResourceCenter}
                componentProps={{ handle: 'LearnandShareSHAPE' }}
            />
        )}

        <AppRoute path="/account" component={Nexus} />

        <AppRoute path={Fortress.settings.config.after_login_url} component={AuthRedirect} exact />

        {/* Not found page */}
        <AppRoute component={NotFound} layout={MainLayout} />
    </Switch>
);
