/* This file is used to enumerate report bar graphs, graph labels,
   and graph categories for TRS, MHQ District and MHQ School

   - Adding a new object to the 'indicators' array will add a new bar to the
     appropriate graph in a report.

    Indicator fields:
    - id: This should match the id in flowData.js
    - title: The label for the bar
    - handle: This handle will match scoring data from the getReportData route. 
              (The domain handle will be appended to the front to match. i.e. "district_quality_teaming_<handle>")
    - category: This is used to create subheadings in the report graphs, and group bars together
                (This string is matched with the appropriate 'categories' array at the bottom of this file)
    - group: 'school' or 'district' - This is used to group indicators together in the report.
*/
export const TRSscoring = {
    TRS_wssplanning: {
        id: 8,
        handle: 'TRS_wssplanning',
        title: 'Whole School Safety Planning',
        score: '',
        indicators: [
            {
                title: 'Adequate Supervision',
                handle: 'AdequateSupervision',
                group: 'school',
            },
            {
                title: 'Bullying Prevention',
                handle: 'BullyingPrevention',
                group: 'school',
            },
            {
                title: 'Safe Predictable Campus',
                handle: 'SafePredictableCampus',
                group: 'school',
            },
            {
                title: 'Threat Assessment Strategy',
                handle: 'ThreatAssessmentStrategy',
                group: 'school',
            },
        ],
    },
    TRS_wsprevention: {
        id: 9,
        handle: 'TRS_wsprevention',
        title: 'Whole School Prevention Planning',
        score: '',
        indicators: [
            {
                title: 'Peer Reporting',
                handle: 'PeerReporting',
                group: 'school',
            },
            {
                title: 'Record Sharing',
                handle: 'RecordSharing',
                group: 'school',
            },
            {
                title: 'Trauma Informed Emergency Drills',
                handle: 'TraumaInformedEmergencyDrills',
                group: 'school',
            },
            {
                title: 'School Climate Assessment',
                handle: 'school_climate_assessment',
                group: 'school',
            },
            {
                title: 'Schoolwide Behavioral Expectations',
                handle: 'schoolwide_behavioral_expectations',
                group: 'school',
            },
        ],
    },
    TRS_wstrauma: {
        id: 10,
        handle: 'TRS_wstrauma',
        title: 'Whole School Trauma Programming',
        score: '',
        indicators: [
            {
                title: 'Crisis Response Training',
                handle: 'CrisisResponseTraining',
                group: 'school',
            },
            {
                title: 'Restorative Practices',
                handle: 'RestorativePractices',
                group: 'school',
            },
            {
                title: 'Staff Trauma Knowledge',
                handle: 'StaffTraumaKnowledge',
                group: 'school',
            },
            {
                title: 'Staff Trauma Skills',
                handle: 'StaffTraumaSkills',
                group: 'school',
            },
            {
                title: 'Trauma Informed Discipline',
                handle: 'TraumaInformedDiscipline',
                group: 'school',
            },
            {
                title: 'Trauma Informed Security Staff',
                handle: 'TraumaInformedSecurityStaff',
                group: 'school',
            },
        ],
    },
    TRS_classroomstrat: {
        id: 11,
        handle: 'TRS_classroomstrat',
        title: 'Classroom Strategies',
        score: '',
        indicators: [
            {
                title: 'Academic Accomodations',
                handle: 'AcademicAccomodations',
                group: 'school',
            },
            {
                title: 'Behavioral Accomodations',
                handle: 'BehavioralAccomodations',
                group: 'school',
            },
            {
                title: 'Calm Safe Classrooms',
                handle: 'CalmSafeClassrooms',
                group: 'school',
            },
            {
                title: 'Social Emotional Learning Programs',
                handle: 'SocialEmotionalLearningPrograms',
                group: 'school',
            },
        ],
    },
    TRS_prevention_earlyintervention: {
        id: 12,
        handle: 'TRS_prevention_earlyintervention',
        title: 'Prevention/ Early Intervention Trauma Programming',
        score: '',
        indicators: [
            {
                title: 'Trauma Informed Evidence-Based Practices',
                handle: 'TIEvidenceBasedPractices',
                group: 'school',
            },
            {
                title: 'Trauma Exposure Assessment',
                handle: 'TraumaExposureAssessment',
                group: 'school',
            },
        ],
    },
    TRS_tiprogramming: {
        id: 13,
        handle: 'TRS_tiprogramming',
        title: 'Targeted Trauma-Informed Programming',
        score: '',
        indicators: [
            {
                title: 'Community Mental Health Partnership',
                handle: 'CommunityMentalHealthPartnership',
                group: 'school',
            },
            {
                title: 'Multidisciplinary Team',
                handle: 'MultidisciplinaryTeam',
                group: 'school',
            },
        ],
    },
    TRS_staffselfcare: {
        id: 14,
        handle: 'TRS_staffselfcare',
        title: 'Staff Self Care',
        score: '',
        indicators: [
            {
                title: 'Peer Support',
                handle: 'PeerSupport',
                group: 'school',
            },
            {
                title: 'Staff Awareness',
                handle: 'StaffAwareness',
                group: 'school',
            },
            {
                title: 'Wellness Resources',
                handle: 'WellnessResources',
                group: 'school',
            },
        ],
    },
    TRS_familyeng: {
        id: 15,
        handle: 'TRS_familyeng',
        title: 'Family and Community Engagement',
        score: '',
        indicators: [
            {
                title: 'Community Partnership',
                handle: 'CommunityPartnership',
                group: 'school',
            },
            {
                title: 'Family Outreach Education',
                handle: 'FamilyOutreachEducation',
                group: 'school',
            },
            {
                title: 'Racial Ethnic Sensitive Resources',
                handle: 'RacialEthnicSensitiveResources',
                group: 'school',
            },
            {
                title: 'Racial Ethnic Sensitivity Training',
                handle: 'RacialEthnicSensitivityTraining',
                group: 'school',
            },
        ],
    },
};

export const MHQscoringSchool = {
    school_quality_teaming: {
        id: 1,
        handle: 'school_quality_teaming',
        title: 'Teaming',
        score: '',
        schoolScore: '',
        districtScore: '',
        indicators: [
            {
                title: 'Multidisciplinary teams',
                handle: 'MultidisciplinaryTeams',
                category: 'partners',
                group: 'school',
            },
            {
                title: 'Youth and family partnership',
                handle: 'YouthFamilyPartnership',
                category: 'partners',
                group: 'school',
            },
            {
                title: 'Community partnerships',
                handle: 'CommunityPartnerships',
                category: 'partners',
                group: 'school',
            },
            {
                title: 'Addresses all tiers',
                handle: 'AddressesAllTiers',
                category: 'structure',
                group: 'school',
            },
            {
                title: 'Avoid duplication and promote efficiency',
                handle: 'DuplicationEfficiency',
                category: 'structure',
                group: 'school',
            },
            {
                title: 'Best practices for meetings',
                handle: 'MeetingStructureProcess',
                category: 'structure',
                group: 'school',
            },
            {
                title: 'Delineated roles/responsibilities',
                handle: 'DelineatedRoles',
                category: 'structure',
                group: 'school',
            },
            {
                title: 'Effective referral processes to school mental health services',
                handle: 'ReferralProcessesSchool',
                category: 'structure',
                group: 'school',
            },
            {
                title: 'Effective referral processes to community services',
                handle: 'ReferralProcessesCommunity',
                category: 'structure',
                group: 'school',
            },
            {
                title: 'Data based decisions for interventions',
                handle: 'DataBased',
                category: 'data',
                group: 'school',
            },
            {
                title: 'Data sharing',
                handle: 'DataSharing',
                category: 'data',
                group: 'school',
            },
        ],
    },
    school_quality_needsassessment: {
        id: 2,
        handle: 'school_quality_needsassessment',
        title: 'Needs Assessment/ Resource Mapping',
        score: '',
        indicators: [
            {
                title: 'Assess student mental health needs',
                handle: 'StudentNeeds',
                category: 'needsassessment',
                group: 'school',
            },
            {
                title: 'Assess student mental health strengths',
                handle: 'StudentStrengths',
                category: 'needsassessment',
                group: 'school',
            },
            {
                title: 'Use needs assessment results to select, plan and implement services and supports',
                handle: 'ResultsToSelect',
                category: 'needsassessment',
                group: 'school',
            },
            {
                title: 'Conduct resource mapping to identify existing services and supports',
                handle: 'ResourceMapping',
                category: 'resourcemapping',
                group: 'school',
            },
            {
                title: 'Use resource map to select, plan and implement services and supports',
                handle: 'ResourceMappingToSelect',
                category: 'resourcemapping',
                group: 'school',
            },
            {
                title: 'Align existing services and supports',
                handle: 'AlignExisting',
                category: 'alignment',
                group: 'school',
            },
        ],
    },
    school_quality_screening: {
        id: 3,
        handle: 'school_quality_screening',
        title: 'Screening',
        score: '',
        indicators: [
            {
                title: 'Use best practices for mental health screening, planning, and implementation',
                handle: 'BestPractices',
                group: 'school',
            },
            {
                title: 'Enrolled in school',
                handle: 'Enrolled',
                group: 'school',
            },
            {
                title: 'Formally screened in the absence of known risk factors',
                handle: 'Screened',
                group: 'school',
            },
            {
                title: 'Referred to a mental health service following identification',
                handle: 'Referred',
                group: 'school',
            },
            {
                title: 'Received',
                handle: 'Received',
                group: 'school',
            },
            {
                title: 'Identified as being at-risk or already experiencing a mental health problem',
                handle: 'IdentifiedAtRisk',
                group: 'school',
            },
            {
                title: 'Number of students identified were from marginalized populations (e.g., BIPOC, LGBTQ+, immigrant)',
                handle: 'MarginalizedGroups',
                group: 'school',
            },
            {
                title: 'System-level changes (e.g., training school staff in trauma-informed practices, revising discipline policies) were implemented',
                handle: 'SystemLevelChanges',
                group: 'school',
            },
            {
                title: 'The system-level changes (e.g., training school staff in trauma-informed practices, revising discipline policies) implemented',
                handle: 'SystemLevelChangesImplemented',
                group: 'school',
            },
            {
                title: 'Screened Depression',
                handle: 'ScreenedDepression',
                group: 'school',
            },
            {
                title: 'Screened Suicide',
                handle: 'ScreenedSuicide',
                group: 'school',
            },
            {
                title: 'Screened Substance Use',
                handle: 'ScreenedSubstanceUse',
                group: 'school',
            },
            {
                title: 'Screened Trauma',
                handle: 'ScreenedTrauma',
                group: 'school',
            },
            {
                title: 'Screened Anxiety',
                handle: 'ScreenedAnxiety',
                group: 'school',
            },
            {
                title: 'Screened General',
                handle: 'ScreenedGeneral',
                group: 'school',
            },
            {
                title: 'Screened Wellbeing',
                handle: 'ScreenedWellBeing',
                group: 'school',
            },
            {
                title: 'Screened Social Determinants',
                handle: 'ScreenedSocialDeterminants',
                group: 'school',
            },
            {
                title: 'Screened User Added 1',
                handle: 'ScreenedUserAdded_1',
                group: 'school',
            },
            {
                title: 'Screened User Added 1 Text',
                handle: 'ScreenedUserAdded_1_text',
                group: 'school',
            },
            {
                title: 'Screened User Added 2',
                handle: 'ScreenedUserAdded_2',
                group: 'school',
            },
            {
                title: 'Screened User Added 2 Text',
                handle: 'ScreenedUserAdded_2_text',
                group: 'school',
            },
            {
                title: 'Screened User Added 3',
                handle: 'ScreenedUserAdded_3',
                group: 'school',
            },
            {
                title: 'Screened User Added 3 Text',
                handle: 'ScreenedUserAdded_3_text',
                group: 'school',
            },
            {
                title: 'Screened User Added 4',
                handle: 'ScreenedUserAdded_4',
                group: 'school',
            },
            {
                title: 'Screened User Added 4 Text',
                handle: 'ScreenedUserAdded_4_text',
                group: 'school',
            },
            {
                title: 'Screened User Added 5',
                handle: 'ScreenedUserAdded_5',
                group: 'school',
            },
            {
                title: 'Screened User Added 5 Text',
                handle: 'ScreenedUserAdded_5_text',
                group: 'school',
            },
        ],
    },
    school_quality_MHpromotion: {
        id: 4,
        handle: 'school_quality_MHpromotion',
        title: 'Mental Health Promotion Services & Supports',
        score: '',
        indicators: [
            {
                title: 'Assess School Climate',
                handle: 'AssessClimate',
                category: 'services',
                group: 'school',
            },
            {
                title: 'Improve School Climate',
                handle: 'ImproveClimate',
                category: 'services',
                group: 'school',
            },
            {
                title: 'Assess Teacher and Staff Well-being',
                handle: 'AssessWellBeing',
                category: 'services',
                group: 'school',
            },
            {
                title: 'Improve Teacher and Staff Well-being',
                handle: 'ImproveWellBeing',
                category: 'services',
                group: 'school',
            },
            {
                title: 'Set Schoolwide Expectations About Positive Behaviors',
                handle: 'PositiveBehaviorsExpectations',
                category: 'services',
                group: 'school',
            },
            {
                title: 'Promote Positive Behaviors Through Positive Reinforcement',
                handle: 'PositiveBehaviorsReinforce',
                category: 'services',
                group: 'school',
            },
            {
                title: 'Build Healthy Relationships',
                handle: 'HealthyRelationships',
                category: 'services',
                group: 'school',
            },
            {
                title: 'Positive Discipline Practices',
                handle: 'PositiveDiscipline',
                category: 'services',
                group: 'school',
            },
            {
                title: 'Mental Health Literacy',
                handle: 'Literacy',
                category: 'services',
                group: 'school',
            },
            {
                title: 'Social Emotional Learning',
                handle: 'Learning',
                category: 'services',
                group: 'school',
            },
            {
                title: 'Percentage of Tier-1 services and supports are evidence-informed',
                handle: 'EvidenceInformedPercentage',
                category: 'implementation',
                group: 'school',
            },
            {
                title: 'Determine whether Tier-1 services and supports are evidence-informed',
                handle: 'EvidenceInformedDetermination',
                category: 'implementation',
                group: 'school',
            },
            {
                title: 'Ensure fit with strengths, needs, cultural, and linguistic considerations',
                handle: 'EnsureFit',
                category: 'implementation',
                group: 'school',
            },
            {
                title: 'Ensure adequate resources for implementation',
                handle: 'AdequateResources',
                category: 'implementation',
                group: 'school',
            },
            {
                title: 'Provide interactive training and ongoing supports',
                handle: 'Training',
                category: 'implementation',
                group: 'school',
            },
            {
                title: 'Monitor fidelity',
                handle: 'Fidelity',
                category: 'implementation',
                group: 'school',
            },
        ],
    },
    school_quality_earlyintervention: {
        id: 5,
        handle: 'school_quality_earlyintervention',
        title: 'Early Intervention and Treatment Services & Supports',
        score: '',
        indicators: [
            {
                title: 'Percentage of students who need Tier 2 services receive them',
                handle: 'Tier2Percentage',
                category: 'services',
                group: 'school',
            },
            {
                title: 'Percentage of students who need Tier 3 services receive them',
                handle: 'Tier3Percentage',
                category: 'services',
                group: 'school',
            },
            {
                title: 'Percentage of Tier 2 services that are evidence-informed',
                handle: 'Tier2EvidenceInformed',
                category: 'implementation',
                group: 'school',
            },
            {
                title: 'Percentage of Tier 3 services that are evidence-informed',
                handle: 'Tier3EvidenceInformed',
                category: 'implementation',
                group: 'school',
            },
            {
                title: 'Determine whether Tier 2/3 services are evidence-informed',
                handle: 'EvidenceInformed',
                category: 'implementation',
                group: 'school',
            },
            {
                title: 'Ensure fit with strengths, needs, cultural, and linguistic considerations',
                handle: 'EnsureFit',
                category: 'implementation',
                group: 'school',
            },
            {
                title: 'Ensure adequate resources for implementation',
                handle: 'AdequateResources',
                category: 'implementation',
                group: 'school',
            },
            {
                title: 'Provide interactive training and ongoing supports',
                handle: 'Training',
                category: 'implementation',
                group: 'school',
            },
            {
                title: 'Monitor fidelity',
                handle: 'Fidelity',
                category: 'implementation',
                group: 'school',
            },
            {
                title: 'Ensure intervention goals are SMART',
                handle: 'SmartGoals',
                category: 'services',
                group: 'school',
            },
            {
                title: 'Monitor student progress across tiers',
                handle: 'StudentProgress',
                category: 'services',
                group: 'school',
            },
            {
                title: 'Implement a systematic protocol for emotional and behavioral crisis response',
                handle: 'CrisisResponse',
                category: 'services',
                group: 'school',
            },
        ],
    },
    school_quality_funding: {
        id: 6,
        handle: 'school_quality_funding',
        title: 'Funding and Sustainability',
        score: '',
        indicators: [
            {
                title: 'Use multiple and diverse funding and resources to support full continuum of school mental health',
                handle: 'MultipleDiverse',
                category: 'funding',
                group: 'school',
            },
            {
                title: 'Leverage funding and resources to attract potential contributors',
                handle: 'Leverage',
                category: 'funding',
                group: 'school',
            },
            {
                title: 'Have strategies in place to retain staff',
                handle: 'RetainStaff',
                category: 'staffretention',
                group: 'school',
            },
            {
                title: 'Maximize expertise and resources of partners to support ongoing professional development',
                handle: 'ProfessionalDevelopment',
                category: 'staffretention',
                group: 'school',
            },
            {
                title: 'Tier 1 (mental health promotion) services',
                handle: 'Tier1',
                category: 'funding',
                group: 'school',
            },
            {
                title: 'Tier 2 (early intervention) services',
                handle: 'Tier2',
                category: 'funding',
                group: 'school',
            },
            {
                title: 'Tier 3 (treatment) services',
                handle: 'Tier3',
                category: 'funding',
                group: 'school',
            },
            {
                title: 'Maximize reimbursements of eligible services',
                handle: 'Reimbursements',
                category: 'funding',
                group: 'school',
            },
        ],
    },
    school_quality_impact: {
        id: 7,
        handle: 'school_quality_impact',
        title: 'Impact',
        score: '',
        indicators: [
            {
                title: 'Document impact on educational outcomes',
                handle: 'DocumentedImpactEducational',
                group: 'school',
            },
            {
                title: 'Document impact of social, emotional, and behavioral outcomes',
                handle: 'DocumentedImpactSocial',
                group: 'school',
            },
            {
                title: 'Disaggregate student mental health service and support data to examine student-level outcomes',
                handle: 'DisaggregateData',
                group: 'school',
            },
            {
                title: 'Document and broadly report the impact of your comprehensive school mental health system',
                handle: 'Report',
                group: 'school',
            },
            {
                title: 'Were eligible to receive Tier 2 or Tier 3 school mental health services',
                handle: 'Identified',
                group: 'schoolValues',
            },
            {
                title: 'Received at least one Tier 2 or Tier 3 service',
                handle: 'Received',
                group: 'schoolValues',
            },
            {
                title: 'Demonstrated documented improvement in educational functioning',
                handle: 'DocumentedEducational',
                group: 'schoolValues',
            },
            {
                title: 'Demonstrated documented improvement in social, emotional and behavioral functioning',
                handle: 'DocumentedSocial',
                group: 'schoolValues',
            },
        ],
    },
};

export const MHQscoringPreschool = {
    pre_family_partnerships: {
        id: 1,
        handle: 'pre_family_partnerships',
        title: 'Family Partnerships',
        score: '',
        preschoolScore: '',
        indicators: [
            {
                title: 'Creates and maintain relationships',
                handle: 'FP1',
                category: '',
                group: 'preschool',
            },
            {
                title: 'Shares decision-making',
                handle: 'FP2',
                category: '',
                group: 'preschool',
            },
            {
                title: 'Ensures staff and materials are available',
                handle: 'FP3',
                category: '',
                group: 'preschool',
            },
            {
                title: 'Provides interactive training and ongoing support',
                handle: 'FP4',
                category: '',
                group: 'preschool',
            },
            {
                title: 'Monitors how well activities are carried out',
                handle: 'FP5',
                category: '',
                group: 'preschool',
            },
            {
                title: 'Determines whether practices are evidence-informed',
                handle: 'FP6',
                category: '',
                group: 'preschool',
            },
            {
                title: 'Ensures fit with strengths, needs, cultural, and linguistic considerations',
                handle: 'FP7',
                category: '',
                group: 'preschool',
            },
            {
                title: 'Supports families in their home and their community',
                handle: 'FP8',
                category: '',
                group: 'preschool',
            },
        ],
    },
    pre_services_and_supports: {
        id: 2,
        handle: 'pre_services_and_supports',
        title: 'Services and Supports',
        score: '',
        indicators: [
            {
                title: 'Ensures fit with strengths, needs, cultural, and linguistic considerations (Tier 1)',
                handle: 'SS9',
                category: '',
                group: 'preschool',
            },
            {
                title: 'Ensures fit with strengths, needs, cultural, and linguistic considerations (Tier 2/3)',
                handle: 'SS10',
                category: '',
                group: 'preschool',
            },
            {
                title: 'Ensures staff and materials are available (Tier 1)',
                handle: 'SS11',
                category: '',
                group: 'preschool',
            },
            {
                title: 'Ensures staff and materials are available (Tier 2/3)',
                handle: 'SS12',
                category: '',
                group: 'preschool',
            },
            {
                title: 'Monitors how well activities are carried out (Tier 1)',
                handle: 'SS13',
                category: '',
                group: 'preschool',
            },
            {
                title: 'Monitors how well activities are carried out (Tier 2/3)',
                handle: 'SS14',
                category: '',
                group: 'preschool',
            },
            {
                title: 'Determines whether activities are evidence-informed (Tier 1)',
                handle: 'SS15',
                category: '',
                group: 'preschool',
            },
            {
                title: 'Determines whether activities are evidence-informed (Tier 2/3)',
                handle: 'SS16',
                category: '',
                group: 'preschool',
            },
            {
                title: 'Provides interactive training and ongoing support (Tier 1)',
                handle: 'SS17',
                category: '',
                group: 'preschool',
            },
            {
                title: 'Provides interactive training and ongoing support (Tier 2/3)',
                handle: 'SS18',
                category: '',
                group: 'preschool',
            },
            {
                title: 'Documents impact on school readiness outcomes',
                handle: 'SS19',
                category: '',
                group: 'preschool',
            },
            {
                title: 'Documents impact on social, emotional, and behavioral outcomes',
                handle: 'SS20',
                category: '',
                group: 'preschool',
            },
            {
                title: 'Separates student mental health intervention data to examine student-level outcomes',
                handle: 'SS21',
                category: '',
                group: 'preschool',
            },
            {
                title: 'Uses multiple and diverse funding and resources to support full continuum of school mental health',
                handle: 'SS22',
                category: '',
                group: 'preschool',
            },
            {
                title: 'Uses funding and resources to attract potential contributors',
                handle: 'SS23',
                category: '',
                group: 'preschool',
            },
        ],
    },
    pre_teaming: {
        id: 3,
        handle: 'pre_teaming',
        title: 'Teaming',
        score: '',
        indicators: [
            {
                title: 'Uses data based decisions for interventions',
                handle: 'T24',
                category: '',
                group: 'preschool',
            },
            {
                title: 'Addresses all tiers',
                handle: 'T25',
                category: '',
                group: 'preschool',
            },
            {
                title: 'Defines roles and responsibilities',
                handle: 'T26',
                category: '',
                group: 'preschool',
            },
            {
                title: 'Uses best practices for meetings',
                handle: 'T27',
                category: '',
                group: 'preschool',
            },
            {
                title: 'Assesses child mental health needs',
                handle: 'T28',
                category: '',
                group: 'preschool',
            },
            {
                title: 'Uses needs assessment results to select, plan and carry out services and supports',
                handle: 'T29',
                category: '',
                group: 'preschool',
            },
            {
                title: 'Promotes community partnerships',
                handle: 'T30',
                category: '',
                group: 'preschool',
            },
            {
                title: 'Uses effective referral practices',
                handle: 'T31',
                category: '',
                group: 'preschool',
            },
        ],
    },
    pre_mental_health_promotion: {
        id: 4,
        handle: 'pre_mental_health_promotion',
        title: 'Mental Health Promotion',
        score: '',
        indicators: [
            {
                title: 'Builds positive relationships',
                handle: 'MHP32',
                category: '',
                group: 'preschool',
            },
            {
                title: 'Sets expectations for positive behaviors',
                handle: 'MHP33',
                category: '',
                group: 'preschool',
            },
            {
                title: 'Uses positive discipline practices',
                handle: 'MHP34',
                category: '',
                group: 'preschool',
            },
            {
                title: 'Uses reinforcement system that promotes positive behaviors',
                handle: 'MHP35',
                category: '',
                group: 'preschool',
            },
            {
                title: 'Increases mental health and child development literacy',
                handle: 'MHP36',
                category: '',
                group: 'preschool',
            },
            {
                title: 'Improves staff well-being',
                handle: 'MHP37',
                category: '',
                group: 'preschool',
            },
            {
                title: 'Improves classroom climate',
                handle: 'MHP38',
                category: '',
                group: 'preschool',
            },
        ],
    },
    pre_kindergarten_transition: {
        id: 5,
        handle: 'pre_kindergarten_transition',
        title: 'Kindergarten Transition',
        score: '',
        indicators: [
            {
                title: 'Involves children in transition practices',
                handle: 'KT39',
                category: '',
                group: 'preschool',
            },
            {
                title: 'Shared decision-making with families',
                handle: 'KT40',
                category: '',
                group: 'preschool',
            },
            {
                title: 'Ensures staff and materials are available',
                handle: 'KT41',
                category: '',
                group: 'preschool',
            },
            {
                title: 'Provides interactive training and ongoing support',
                handle: 'KT42',
                category: '',
                group: 'preschool',
            },
            {
                title: 'Monitors how well activities are carried out',
                handle: 'KT43',
                category: '',
                group: 'preschool',
            },
            {
                title: 'Determines whether activities are evidence-informed',
                handle: 'KT44',
                category: '',
                group: 'preschool',
            },
            {
                title: 'Ensures fit with strengths, needs, cultural, and linguistic considerations',
                handle: 'KT45',
                category: '',
                group: 'preschool',
            },
            {
                title: 'Makes and maintain effective partnerships',
                handle: 'KT46',
                category: '',
                group: 'preschool',
            },
        ],
    },
    pre_mental_health_screening: {
        id: 6,
        handle: 'pre_mental_health_screening',
        title: 'Screening',
        score: '',
        indicators: [
            {
                title: 'Enrolled in school',
                handle: 'MHS1',
                group: 'preschool',
            },
            {
                title: 'Formally screened in the absence of known risk factors',
                handle: 'MHS2',
                group: 'preschool',
            },
            {
                title: 'Identified as being at-risk or already experiencing a mental health problem',
                handle: 'MHS3',
                group: 'preschool',
            },
            {
                title: 'Referred to a mental health service following identification',
                handle: 'MHS4',
                group: 'preschool',
            },
            {
                title: 'Received a mental health service (in-person contact with a preschool or community mental health professional)',
                handle: 'MHS5',
                group: 'preschool',
            },
            {
                title: 'Developmental Delays',
                handle: 'MHS6a1',
                group: 'preschool',
            },
            {
                title: 'Physical Health',
                handle: 'MHS6b1',
                group: 'preschool',
            },
            {
                title: 'Social/Emotional Competencies',
                handle: 'MHS6c1',
                group: 'preschool',
            },
            {
                title: 'Self-Regulation of Mood and Behavior',
                handle: 'MHS6d1',
                group: 'preschool',
            },
            {
                title: 'Trauma',
                handle: 'MHS6e1',
                group: 'preschool',
            },
            {
                title: 'General Mental Health',
                handle: 'MHS6f1',
                group: 'preschool',
            },
            {
                title: 'Well-being or protective factors',
                handle: 'MHS6g1',
                group: 'preschool',
            },
            {
                title: 'Other Mental Health',
                handle: 'MHS6other',
                group: 'preschool',
            },
            {
                title: 'Screened User Added 1',
                handle: 'MHS6h2',
                group: 'preschool',
            },
            {
                title: 'Screened User Added 1 Text',
                handle: 'MHS6h1',
                group: 'preschool',
            },
            {
                title: 'Screened User Added 2',
                handle: 'MHS6i2',
                group: 'preschool',
            },
            {
                title: 'Screened User Added 2 Text',
                handle: 'MHS6i1',
                group: 'preschool',
            },
            {
                title: 'Screened User Added 3',
                handle: 'MHS6j2',
                group: 'preschool',
            },
            {
                title: 'Screened User Added 3 Text',
                handle: 'MHS6j1',
                group: 'preschool',
            },
        ],
    },
};

export const MHQscoringDistrict = {
    district_quality_teaming: {
        id: 1,
        handle: 'district_quality_teaming',
        title: 'Teaming',
        score: '',
        schoolScore: '',
        districtScore: '',
        indicators: [
            {
                title: 'Multidisciplinary teams',
                handle: 'MultidisciplinaryTeams',
                category: 'partners',
                group: 'school',
            },
            {
                title: 'Youth and family partnership',
                handle: 'YouthFamilyPartnership',
                category: 'partners',
                group: 'school',
            },
            {
                title: 'Community partnerships',
                handle: 'CommunityPartnerships',
                category: 'partners',
                group: 'school',
            },
            {
                title: 'Addresses all tiers',
                handle: 'AddressesAllTiers',
                category: 'structure',
                group: 'school',
            },
            {
                title: 'Avoid duplication and promote efficiency',
                handle: 'DuplicationEfficiency',
                category: 'structure',
                group: 'school',
            },
            {
                title: 'Best practices for meetings',
                handle: 'MeetingStructureProcess',
                category: 'structure',
                group: 'school',
            },
            {
                title: 'Delineated roles/responsibilities',
                handle: 'DelineatedRoles',
                category: 'structure',
                group: 'school',
            },
            {
                title: 'Effective referral processes to school mental health services',
                handle: 'ReferralProcessesSchool',
                category: 'structure',
                group: 'school',
            },
            {
                title: 'Effective referral processes to community services',
                handle: 'ReferralProcessesCommunity',
                category: 'structure',
                group: 'school',
            },
            {
                title: 'Data based decisions for interventions',
                handle: 'DataBased',
                category: 'data',
                group: 'school',
            },
            {
                title: 'Data sharing',
                handle: 'DataSharing',
                category: 'data',
                group: 'school',
            },
            {
                title: 'Establish and disseminate written, standard policies and procedures',
                handle: 'EstablishPolicies',
                category: 'support',
                group: 'district',
            },
            {
                title: 'Support implementation',
                handle: 'SupportImplementation',
                category: 'support',
                group: 'district',
            },
            {
                title: 'Monitor implementation',
                handle: 'MonitorImplementation',
                category: 'support',
                group: 'district',
            },
            {
                title: 'Supports',
                handle: 'Supports',
                category: 'support',
                group: 'district',
            },
        ],
    },
    district_quality_needsassessment: {
        id: 2,
        handle: 'district_quality_needsassessment',
        title: 'Needs Assessment/ Resource Mapping',
        score: '',
        indicators: [
            {
                title: 'Assess student mental health needs',
                handle: 'StudentNeeds',
                category: 'needsassessment',
                group: 'school',
            },
            {
                title: 'Assess student mental health strengths',
                handle: 'StudentStrengths',
                category: 'needsassessment',
                group: 'school',
            },
            {
                title: 'Use needs assessment results to select, plan and implement services and supports',
                handle: 'ResultsToSelect',
                category: 'needsassessment',
                group: 'school',
            },
            {
                title: 'Conduct resource mapping to identify existing services and supports',
                handle: 'ResourceMapping',
                category: 'resourcemapping',
                group: 'school',
            },
            {
                title: 'Use resource map to select, plan and implement services and supports',
                handle: 'ResourceMappingToSelect',
                category: 'resourcemapping',
                group: 'school',
            },
            {
                title: 'Align existing services and supports',
                handle: 'AlignExisting',
                category: 'alignment',
                group: 'school',
            },
            {
                title: 'Establish and disseminate written, standard policies and procedures',
                handle: 'EstablishPolicies',
                category: 'support',
                group: 'district',
            },
            {
                title: 'Support implementation',
                handle: 'SupportImplementation',
                category: 'support',
                group: 'district',
            },
            {
                title: 'Monitor implementation',
                handle: 'MonitorImplementation',
                category: 'support',
                group: 'district',
            },
            {
                title: 'Assess and refine district supports',
                handle: 'Supports',
                category: 'support',
                group: 'district',
            },
        ],
    },
    district_quality_screening: {
        id: 3,
        handle: 'district_quality_screening',
        title: 'Screening',
        score: '',
        indicators: [
            {
                title: 'Use best practices for mental health screening, planning, and implementation',
                handle: 'BestPractices',
                group: 'school',
            },
            {
                title: 'Enrolled in school',
                handle: 'Enrolled',
                group: 'school',
            },
            {
                title: 'Formally screened in the absence of known risk factors',
                handle: 'Screened',
                group: 'school',
            },
            {
                title: 'Referred to a mental health service following identification',
                handle: 'Referred',
                group: 'school',
            },
            {
                title: 'Received',
                handle: 'Received',
                group: 'school',
            },
            {
                title: 'Identified as being at-risk or already experiencing a mental health problem',
                handle: 'IdentifiedAtRisk',
                group: 'school',
            },
            {
                title: 'Number of students identified were from marginalized populations (e.g., BIPOC, LGBTQ+, immigrant)',
                handle: 'MarginalizedGroups',
                group: 'school',
            },
            {
                title: 'System-level changes (e.g., training school staff in trauma-informed practices, revising discipline policies) were implemented',
                handle: 'SystemLevelChanges',
                group: 'school',
            },
            {
                title: 'The system-level changes (e.g., training school staff in trauma-informed practices, revising discipline policies) implemented',
                handle: 'SystemLevelChangesImplemented',
                group: 'school',
            },
            {
                title: 'Screened Depression',
                handle: 'ScreenedDepression',
                group: 'school',
            },
            {
                title: 'Screened Suicide',
                handle: 'ScreenedSuicide',
                group: 'school',
            },
            {
                title: 'Screened Substance Use',
                handle: 'ScreenedSubstanceUse',
                group: 'school',
            },
            {
                title: 'Screened Trauma',
                handle: 'ScreenedTrauma',
                group: 'school',
            },
            {
                title: 'Screened Anxiety',
                handle: 'ScreenedAnxiety',
                group: 'school',
            },
            {
                title: 'Screened General',
                handle: 'ScreenedGeneral',
                group: 'school',
            },
            {
                title: 'Screened Wellbeing',
                handle: 'ScreenedWellBeing',
                group: 'school',
            },
            {
                title: 'Screened Social Determinants',
                handle: 'ScreenedSocialDeterminants',
                group: 'school',
            },

            {
                title: 'Screened User Added 1',
                handle: 'ScreenedUserAdded_1',
                group: 'school',
            },
            {
                title: 'Screened User Added 1 Text',
                handle: 'ScreenedUserAdded_1_text',
                group: 'school',
            },
            {
                title: 'Screened User Added 2',
                handle: 'ScreenedUserAdded_2',
                group: 'school',
            },
            {
                title: 'Screened User Added 2 Text',
                handle: 'ScreenedUserAdded_2_text',
                group: 'school',
            },
            {
                title: 'Screened User Added 3',
                handle: 'ScreenedUserAdded_3',
                group: 'school',
            },
            {
                title: 'Screened User Added 3 Text',
                handle: 'ScreenedUserAdded_3_text',
                group: 'school',
            },
            {
                title: 'Screened User Added 4',
                handle: 'ScreenedUserAdded_4',
                group: 'school',
            },
            {
                title: 'Screened User Added 4 Text',
                handle: 'ScreenedUserAdded_4_text',
                group: 'school',
            },
            {
                title: 'Screened User Added 5',
                handle: 'ScreenedUserAdded_5',
                group: 'school',
            },
            {
                title: 'Screened User Added 5 Text',
                handle: 'ScreenedUserAdded_5_text',
                group: 'school',
            },
            {
                title: 'Establish and disseminate written, standard policies and procedures',
                handle: 'EstablishPolicies',
                group: 'district',
            },
            {
                title: 'Support implementation',
                handle: 'SupportImplementation',
                group: 'district',
            },
            {
                title: 'Monitor implementation',
                handle: 'MonitorImplementation',
                group: 'district',
            },
            {
                title: 'Assess and monitor district supports',
                handle: 'Supports',
                group: 'district',
            },
        ],
    },
    district_quality_MHpromotion: {
        id: 4,
        handle: 'district_quality_MHpromotion',
        title: 'Mental Health Promotion Services & Supports',
        score: '',
        indicators: [
            {
                title: 'Assess School Climate',
                handle: 'AssessClimate',
                category: 'services',
                group: 'school',
            },
            {
                title: 'Improve School Climate',
                handle: 'ImproveClimate',
                category: 'services',
                group: 'school',
            },
            {
                title: 'Teacher and School Staff Assess Well-being',
                handle: 'AssessWellBeing',
                category: 'services',
                group: 'school',
            },
            {
                title: 'Teacher and School Staff Improve Well-being',
                handle: 'ImproveWellBeing',
                category: 'services',
                group: 'school',
            },
            {
                title: 'Set Schoolwide Expectations About Positive Behaviors',
                handle: 'PositiveBehaviorsExpectations',
                category: 'services',
                group: 'school',
            },
            {
                title: 'Promote Positive Behaviors Through Positive Reinforcement',
                handle: 'PositiveBehaviorsReinforce',
                category: 'services',
                group: 'school',
            },
            {
                title: 'Build Healthy Relationships',
                handle: 'HealthyRelationships',
                category: 'services',
                group: 'school',
            },
            {
                title: 'Positive Discipline Practices',
                handle: 'PositiveDiscipline',
                category: 'services',
                group: 'school',
            },
            {
                title: 'Mental Health Literacy',
                handle: 'Literacy',
                category: 'services',
                group: 'school',
            },
            {
                title: 'Social Emotional Learning',
                handle: 'Learning',
                category: 'services',
                group: 'school',
            },
            {
                title: 'Percentage of Tier-1 services and supports are evidence-informed',
                handle: 'EvidenceInformedPercentage',
                category: 'implementation',
                group: 'school',
            },
            {
                title: 'Determine whether Tier-1 services and supports are evidence-informed',
                handle: 'EvidenceInformedDetermination',
                category: 'implementation',
                group: 'school',
            },
            {
                title: 'Ensure fit with strengths, needs, cultural, and linguistic considerations',
                handle: 'EnsureFit',
                category: 'implementation',
                group: 'school',
            },
            {
                title: 'Ensure adequate resources for implementation',
                handle: 'AdequateResources',
                category: 'implementation',
                group: 'school',
            },
            {
                title: 'Provide interactive training and ongoing supports',
                handle: 'Training',
                category: 'implementation',
                group: 'school',
            },
            {
                title: 'Monitor fidelity',
                handle: 'Fidelity',
                category: 'implementation',
                group: 'school',
            },
            {
                title: 'Establish and disseminate written, standard policies and procedures',
                handle: 'EstablishPolicies',
                category: 'support',
                group: 'district',
            },
            {
                title: 'Support implementation',
                handle: 'SupportImplementation',
                category: 'support',
                group: 'district',
            },
            {
                title: 'Monitor implementation',
                handle: 'MonitorImplementation',
                category: 'support',
                group: 'district',
            },
            {
                title: 'Assess and refine district supports',
                handle: 'Supports',
                category: 'support',
                group: 'district',
            },
        ],
    },
    district_quality_earlyintervention: {
        id: 5,
        handle: 'district_quality_earlyintervention',
        title: 'Early Intervention and Treatment Services & Supports',
        score: '',
        indicators: [
            {
                title: 'Percentage of students who need Tier 2 services receive them',
                handle: 'Tier2Percentage',
                category: 'services',
                group: 'school',
            },
            {
                title: 'Percentage of students who need Tier 3 services receive them',
                handle: 'Tier3Percentage',
                category: 'services',
                group: 'school',
            },
            {
                title: 'Percentage of Tier 2 services that are evidence-informed',
                handle: 'Tier2EvidenceInformed',
                category: 'implementation',
                group: 'school',
            },
            {
                title: 'Percentage of Tier 3 services that are evidence-informed',
                handle: 'Tier3EvidenceInformed',
                category: 'implementation',
                group: 'school',
            },
            {
                title: 'Determine whether Tier 2/3 services are evidence-informed',
                handle: 'EvidenceInformed',
                category: 'implementation',
                group: 'school',
            },
            {
                title: 'Ensure fit with strengths, needs, cultural, and linguistic considerations',
                handle: 'EnsureFit',
                category: 'implementation',
                group: 'school',
            },
            {
                title: 'Ensure adequate resources for implementation',
                handle: 'AdequateResources',
                category: 'implementation',
                group: 'school',
            },
            {
                title: 'Provide interactive training and ongoing supports',
                handle: 'Training',
                category: 'implementation',
                group: 'school',
            },
            {
                title: 'Monitor fidelity',
                handle: 'Fidelity',
                category: 'implementation',
                group: 'school',
            },
            {
                title: 'Ensure intervention goals are SMART',
                handle: 'SmartGoals',
                category: 'services',
                group: 'school',
            },
            {
                title: 'Monitor student progress across tiers',
                handle: 'StudentProgress',
                category: 'services',
                group: 'school',
            },
            {
                title: 'Implement a systematic protocol for emotional and behavioral crisis response',
                handle: 'CrisisResponse',
                category: 'services',
                group: 'school',
            },
            {
                title: 'Establish and disseminate written, standard policies and procedures',
                handle: 'EstablishPolicies',
                category: 'support',
                group: 'district',
            },
            {
                title: 'Support implementation',
                handle: 'SupportImplementation',
                category: 'support',
                group: 'district',
            },
            {
                title: 'Monitor implementation',
                handle: 'MonitorImplementation',
                category: 'support',
                group: 'district',
            },
            {
                title: 'Assess and refine distric supports',
                handle: 'Supports',
                category: 'support',
                group: 'district',
            },
        ],
    },
    district_quality_funding: {
        id: 6,
        handle: 'district_quality_funding',
        title: 'Funding and Sustainability',
        score: '',
        indicators: [
            {
                title: 'Use multiple and diverse funding and resources to support full continuum of school mental health',
                handle: 'MultipleDiverse',
                category: 'funding',
                group: 'school',
            },
            {
                title: 'Leverage funding and resources to attract potential contributors',
                handle: 'Leverage',
                category: 'funding',
                group: 'school',
            },
            {
                title: 'Have strategies in place to retain staff',
                handle: 'RetainStaff',
                category: 'staffretention',
                group: 'school',
            },
            {
                title: 'Maximize expertise and resources of partners to support ongoing professional development',
                handle: 'ProfessionalDevelopment',
                category: 'staffretention',
                group: 'school',
            },
            {
                title: 'Tier 1 (mental health promotion) services',
                handle: 'Tier1',
                category: 'funding',
                group: 'school',
            },
            {
                title: 'Tier 2 (early intervention) services',
                handle: 'Tier2',
                category: 'funding',
                group: 'school',
            },
            {
                title: 'Tier 3 (treatment) services',
                handle: 'Tier3',
                category: 'funding',
                group: 'school',
            },
            {
                title: 'Maximize reimbursements of eligible services',
                handle: 'Reimbursements',
                category: 'funding',
                group: 'school',
            },
            {
                title: 'Develop relationships and collaborate with local leaders to foster funding and sustainability support',
                handle: 'LocalFunding',
                category: 'quality',
                group: 'district',
            },
            {
                title: 'Develop relationships and collaborate with state/territory leaders to foster funding and sustainability support',
                handle: 'StateFunding',
                category: 'quality',
                group: 'district',
            },
            {
                title: 'Fairly allocate resource across the district',
                handle: 'AllocateFairly',
                category: 'quality',
                group: 'district',
            },
            {
                title: 'Provide guidance and support to schools on funding and sustainability',
                handle: 'Supports',
                category: 'support',
                group: 'district',
            },
        ],
    },
    district_quality_impact: {
        id: 7,
        handle: 'district_quality_impact',
        title: 'Impact',
        score: '',
        indicators: [
            {
                title: 'Document impact on educational outcomes',
                handle: 'DocumentedEducational',
                group: 'school',
            },
            {
                title: 'Document impact of social, emotional, and behavioral outcomes',
                handle: 'DocumentedSocial',
                group: 'school',
            },
            {
                title: 'Disaggregate student mental health service and support data to examine student-level outcomes',
                handle: 'DisaggregateData',
                group: 'school',
            },
            {
                title: 'Document and broadly report the impact of your comprehensive school mental health system',
                handle: 'Report',
                group: 'school',
            },
            {
                title: 'Document impact on educational outcomes',
                handle: 'DocumentedImpactEducational',
                group: 'districtReporting',
            },
            {
                title: 'Document impact of social, emotional, and behavioral outcomes',
                handle: 'DocumentedImpactSocial',
                group: 'districtReporting',
            },
            {
                title: 'Disaggregate student mental health service and support data to examine student-level outcomes',
                handle: 'DistrictDisaggregateData',
                group: 'districtReporting',
            },
            {
                title: 'Document and broadly report the impact of your comprehensive school mental health system',
                handle: 'DistrictImpactReport',
                group: 'districtReporting',
            },
            {
                title: 'Establish and disseminate written, standard policies and procedures',
                handle: 'DistrictEstablishPolicies',
                group: 'district',
            },
            {
                title: 'Support implementation',
                handle: 'DistrictSupportImplementation',
                group: 'district',
            },
            {
                title: 'Monitor implementation',
                handle: 'DistrictMonitorDocumentation',
                group: 'district',
            },
            {
                title: 'Assess and monitor district supports',
                handle: 'DistrictSupports',
                group: 'district',
            },
        ],
    },
};

export const MHQscoringEntity = {
    entity_quality_teaming: {
        id: 1,
        handle: 'entity_quality_teaming',
        title: 'Teaming',
        score: '',
        schoolScore: '',
        districtScore: '',
        indicators: [
            {
                title: 'Multidisciplinary teams',
                handle: 'MultidisciplinaryTeams',
                category: 'partners',
                group: 'school',
            },
            {
                title: 'Youth and family partnership',
                handle: 'YouthFamilyPartnership',
                category: 'partners',
                group: 'school',
            },
            {
                title: 'Community partnerships',
                handle: 'CommunityPartnerships',
                category: 'partners',
                group: 'school',
            },
            {
                title: 'Addresses all tiers',
                handle: 'AddressesAllTiers',
                category: 'structure',
                group: 'school',
            },
            {
                title: 'Avoid duplication and promote efficiency',
                handle: 'DuplicationEfficiency',
                category: 'structure',
                group: 'school',
            },
            {
                title: 'Best practices for meetings',
                handle: 'MeetingStructureProcess',
                category: 'structure',
                group: 'school',
            },
            {
                title: 'Delineated roles/responsibilities',
                handle: 'DelineatedRoles',
                category: 'structure',
                group: 'school',
            },
            {
                title: 'Effective referral processes to school mental health services',
                handle: 'ReferralProcessesSchool',
                category: 'structure',
                group: 'school',
            },
            {
                title: 'Effective referral processes to community services',
                handle: 'ReferralProcessesCommunity',
                category: 'structure',
                group: 'school',
            },
            {
                title: 'Data based decisions for interventions',
                handle: 'DataBased',
                category: 'data',
                group: 'school',
            },
            {
                title: 'Data sharing',
                handle: 'DataSharing',
                category: 'data',
                group: 'school',
            },
            {
                title: 'Establish and disseminate written, standard policies and procedures',
                handle: 'EstablishPolicies',
                category: 'support',
                group: 'district',
            },
            {
                title: 'Support implementation',
                handle: 'SupportImplementation',
                category: 'support',
                group: 'district',
            },
            {
                title: 'Monitor implementation',
                handle: 'MonitorImplementation',
                category: 'support',
                group: 'district',
            },
            {
                title: 'Supports',
                handle: 'Supports',
                category: 'support',
                group: 'district',
            },
        ],
    },
    entity_quality_needsassessment: {
        id: 2,
        handle: 'entity_quality_needsassessment',
        title: 'Needs Assessment/ Resource Mapping',
        score: '',
        indicators: [
            {
                title: 'Assess student mental health needs',
                handle: 'StudentNeeds',
                category: 'needsassessment',
                group: 'school',
            },
            {
                title: 'Assess student mental health strengths',
                handle: 'StudentStrengths',
                category: 'needsassessment',
                group: 'school',
            },
            {
                title: 'Use needs assessment results to select, plan and implement services and supports',
                handle: 'ResultsToSelect',
                category: 'needsassessment',
                group: 'school',
            },
            {
                title: 'Conduct resource mapping to identify existing services and supports',
                handle: 'ResourceMapping',
                category: 'resourcemapping',
                group: 'school',
            },
            {
                title: 'Use resource map to select, plan and implement services and supports',
                handle: 'ResourceMappingToSelect',
                category: 'resourcemapping',
                group: 'school',
            },
            {
                title: 'Align existing services and supports',
                handle: 'AlignExisting',
                category: 'alignment',
                group: 'school',
            },
            {
                title: 'Establish and disseminate written, standard policies and procedures',
                handle: 'EstablishPolicies',
                category: 'support',
                group: 'district',
            },
            {
                title: 'Support implementation',
                handle: 'SupportImplementation',
                category: 'support',
                group: 'district',
            },
            {
                title: 'Monitor implementation',
                handle: 'MonitorImplementation',
                category: 'support',
                group: 'district',
            },
            {
                title: 'Assess and refine entity supports',
                handle: 'Supports',
                category: 'support',
                group: 'district',
            },
        ],
    },
    entity_quality_screening: {
        id: 3,
        handle: 'entity_quality_screening',
        title: 'Screening',
        score: '',
        indicators: [
            {
                title: 'Use best practices for mental health screening, planning, and implementation',
                handle: 'BestPractices',
                group: 'school',
            },
            {
                title: 'Enrolled in school',
                handle: 'Enrolled',
                group: 'school',
            },
            {
                title: 'Formally screened in the absence of known risk factors',
                handle: 'Screened',
                group: 'school',
            },
            {
                title: 'Referred to a mental health service following identification',
                handle: 'Referred',
                group: 'school',
            },
            {
                title: 'Received',
                handle: 'Received',
                group: 'school',
            },
            {
                title: 'Identified as being at-risk or already experiencing a mental health problem',
                handle: 'IdentifiedAtRisk',
                group: 'school',
            },
            {
                title: 'Number of students identified were from marginalized populations (e.g., BIPOC, LGBTQ+, immigrant)',
                handle: 'MarginalizedGroups',
                group: 'school',
            },
            {
                title: 'System-level changes (e.g., training school staff in trauma-informed practices, revising discipline policies) were implemented',
                handle: 'SystemLevelChanges',
                group: 'school',
            },
            {
                title: 'The system-level changes (e.g., training school staff in trauma-informed practices, revising discipline policies) implemented',
                handle: 'SystemLevelChangesImplemented',
                group: 'school',
            },

            {
                title: 'Screened Depression',
                handle: 'ScreenedDepression',
                group: 'school',
            },
            {
                title: 'Screened Suicide',
                handle: 'ScreenedSuicide',
                group: 'school',
            },
            {
                title: 'Screened Substance Use',
                handle: 'ScreenedSubstanceUse',
                group: 'school',
            },
            {
                title: 'Screened Trauma',
                handle: 'ScreenedTrauma',
                group: 'school',
            },
            {
                title: 'Screened Anxiety',
                handle: 'ScreenedAnxiety',
                group: 'school',
            },
            {
                title: 'Screened General',
                handle: 'ScreenedGeneral',
                group: 'school',
            },
            {
                title: 'Screened Wellbeing',
                handle: 'ScreenedWellBeing',
                group: 'school',
            },
            {
                title: 'Screened Social Determinants',
                handle: 'ScreenedSocialDeterminants',
                group: 'school',
            },

            {
                title: 'Screened User Added 1',
                handle: 'ScreenedUserAdded_1',
                group: 'school',
            },
            {
                title: 'Screened User Added 1 Text',
                handle: 'ScreenedUserAdded_1_text',
                group: 'school',
            },
            {
                title: 'Screened User Added 2',
                handle: 'ScreenedUserAdded_2',
                group: 'school',
            },
            {
                title: 'Screened User Added 2 Text',
                handle: 'ScreenedUserAdded_2_text',
                group: 'school',
            },
            {
                title: 'Screened User Added 3',
                handle: 'ScreenedUserAdded_3',
                group: 'school',
            },
            {
                title: 'Screened User Added 3 Text',
                handle: 'ScreenedUserAdded_3_text',
                group: 'school',
            },
            {
                title: 'Screened User Added 4',
                handle: 'ScreenedUserAdded_4',
                group: 'school',
            },
            {
                title: 'Screened User Added 4 Text',
                handle: 'ScreenedUserAdded_4_text',
                group: 'school',
            },
            {
                title: 'Screened User Added 5',
                handle: 'ScreenedUserAdded_5',
                group: 'school',
            },
            {
                title: 'Screened User Added 5 Text',
                handle: 'ScreenedUserAdded_5_text',
                group: 'school',
            },
            {
                title: 'Establish and disseminate written, standard policies and procedures',
                handle: 'EstablishPolicies',
                group: 'district',
            },
            {
                title: 'Support implementation',
                handle: 'SupportImplementation',
                group: 'district',
            },
            {
                title: 'Monitor implementation',
                handle: 'MonitorImplementation',
                group: 'district',
            },
            {
                title: 'Assess and monitor entity supports',
                handle: 'Supports',
                group: 'district',
            },
        ],
    },
    entity_quality_MHpromotion: {
        id: 4,
        handle: 'entity_quality_MHpromotion',
        title: 'Mental Health Promotion Services & Supports',
        score: '',
        indicators: [
            {
                title: 'Assess School Climate',
                handle: 'AssessClimate',
                category: 'services',
                group: 'school',
            },
            {
                title: 'Improve School Climate',
                handle: 'ImproveClimate',
                category: 'services',
                group: 'school',
            },
            {
                title: 'Teacher and School Staff Assess Well-being',
                handle: 'AssessWellBeing',
                category: 'services',
                group: 'school',
            },
            {
                title: 'Teacher and School Staff Improve Well-being',
                handle: 'ImproveWellBeing',
                category: 'services',
                group: 'school',
            },
            {
                title: 'Set Schoolwide Expectations About Positive Behaviors',
                handle: 'PositiveBehaviorsExpectations',
                category: 'services',
                group: 'school',
            },
            {
                title: 'Promote Positive Behaviors Through Positive Reinforcement',
                handle: 'PositiveBehaviorsReinforce',
                category: 'services',
                group: 'school',
            },
            {
                title: 'Build Healthy Relationships',
                handle: 'HealthyRelationships',
                category: 'services',
                group: 'school',
            },
            {
                title: 'Positive Discipline Practices',
                handle: 'PositiveDiscipline',
                category: 'services',
                group: 'school',
            },
            {
                title: 'Mental Health Literacy',
                handle: 'Literacy',
                category: 'services',
                group: 'school',
            },
            {
                title: 'Social Emotional Learning',
                handle: 'Learning',
                category: 'services',
                group: 'school',
            },
            {
                title: 'Percentage of Tier-1 services and supports are evidence-informed',
                handle: 'EvidenceInformedPercentage',
                category: 'implementation',
                group: 'school',
            },
            {
                title: 'Determine whether Tier-1 services and supports are evidence-informed',
                handle: 'EvidenceInformedDetermination',
                category: 'implementation',
                group: 'school',
            },
            {
                title: 'Ensure fit with strengths, needs, cultural, and linguistic considerations',
                handle: 'EnsureFit',
                category: 'implementation',
                group: 'school',
            },
            {
                title: 'Ensure adequate resources for implementation',
                handle: 'AdequateResources',
                category: 'implementation',
                group: 'school',
            },
            {
                title: 'Provide interactive training and ongoing supports',
                handle: 'Training',
                category: 'implementation',
                group: 'school',
            },
            {
                title: 'Monitor fidelity',
                handle: 'Fidelity',
                category: 'implementation',
                group: 'school',
            },
            {
                title: 'Establish and disseminate written, standard policies and procedures',
                handle: 'EstablishPolicies',
                category: 'support',
                group: 'district',
            },
            {
                title: 'Support implementation',
                handle: 'SupportImplementation',
                category: 'support',
                group: 'district',
            },
            {
                title: 'Monitor implementation',
                handle: 'MonitorImplementation',
                category: 'support',
                group: 'district',
            },
            {
                title: 'Assess and refine entity supports',
                handle: 'Supports',
                category: 'support',
                group: 'district',
            },
        ],
    },
    entity_quality_earlyintervention: {
        id: 5,
        handle: 'entity_quality_earlyintervention',
        title: 'Early Intervention and Treatment Services & Supports',
        score: '',
        indicators: [
            {
                title: 'Percentage of students who need Tier 2 services receive them',
                handle: 'Tier2Percentage',
                category: 'services',
                group: 'school',
            },
            {
                title: 'Percentage of students who need Tier 3 services receive them',
                handle: 'Tier3Percentage',
                category: 'services',
                group: 'school',
            },
            {
                title: 'Percentage of Tier 2 services that are evidence-informed',
                handle: 'Tier2EvidenceInformed',
                category: 'implementation',
                group: 'school',
            },
            {
                title: 'Percentage of Tier 3 services that are evidence-informed',
                handle: 'Tier3EvidenceInformed',
                category: 'implementation',
                group: 'school',
            },
            {
                title: 'Determine whether Tier 2/3 services are evidence-informed',
                handle: 'EvidenceInformed',
                category: 'implementation',
                group: 'school',
            },
            {
                title: 'Ensure fit with strengths, needs, cultural, and linguistic considerations',
                handle: 'EnsureFit',
                category: 'implementation',
                group: 'school',
            },
            {
                title: 'Ensure adequate resources for implementation',
                handle: 'AdequateResources',
                category: 'implementation',
                group: 'school',
            },
            {
                title: 'Provide interactive training and ongoing supports',
                handle: 'Training',
                category: 'implementation',
                group: 'school',
            },
            {
                title: 'Monitor fidelity',
                handle: 'Fidelity',
                category: 'implementation',
                group: 'school',
            },
            {
                title: 'Ensure intervention goals are SMART',
                handle: 'SmartGoals',
                category: 'services',
                group: 'school',
            },
            {
                title: 'Monitor student progress across tiers',
                handle: 'StudentProgress',
                category: 'services',
                group: 'school',
            },
            {
                title: 'Implement a systematic protocol for emotional and behavioral crisis response',
                handle: 'CrisisResponse',
                category: 'services',
                group: 'school',
            },
            {
                title: 'Establish and disseminate written, standard policies and procedures',
                handle: 'EstablishPolicies',
                category: 'support',
                group: 'district',
            },
            {
                title: 'Support implementation',
                handle: 'SupportImplementation',
                category: 'support',
                group: 'district',
            },
            {
                title: 'Monitor implementation',
                handle: 'MonitorImplementation',
                category: 'support',
                group: 'district',
            },
            {
                title: 'Assess and refine distric supports',
                handle: 'Supports',
                category: 'support',
                group: 'district',
            },
        ],
    },
    entity_quality_funding: {
        id: 6,
        handle: 'entity_quality_funding',
        title: 'Funding and Sustainability',
        score: '',
        indicators: [
            {
                title: 'Use multiple and diverse funding and resources to support full continuum of school mental health',
                handle: 'MultipleDiverse',
                category: 'funding',
                group: 'school',
            },
            {
                title: 'Leverage funding and resources to attract potential contributors',
                handle: 'Leverage',
                category: 'funding',
                group: 'school',
            },
            {
                title: 'Have strategies in place to retain staff',
                handle: 'RetainStaff',
                category: 'staffretention',
                group: 'school',
            },
            {
                title: 'Maximize expertise and resources of partners to support ongoing professional development',
                handle: 'ProfessionalDevelopment',
                category: 'staffretention',
                group: 'school',
            },
            {
                title: 'Tier 1 (mental health promotion) services',
                handle: 'Tier1',
                category: 'funding',
                group: 'school',
            },
            {
                title: 'Tier 2 (early intervention) services',
                handle: 'Tier2',
                category: 'funding',
                group: 'school',
            },
            {
                title: 'Tier 3 (treatment) services',
                handle: 'Tier3',
                category: 'funding',
                group: 'school',
            },
            {
                title: 'Maximize reimbursements of eligible services',
                handle: 'Reimbursements',
                category: 'funding',
                group: 'school',
            },
            {
                title: 'Develop relationships and collaborate with local leaders to foster funding and sustainability support',
                handle: 'LocalFunding',
                category: 'quality',
                group: 'district',
            },
            {
                title: 'Develop relationships and collaborate with state/territory leaders to foster funding and sustainability support',
                handle: 'StateFunding',
                category: 'quality',
                group: 'district',
            },
            {
                title: 'Fairly allocate resource across the entity',
                handle: 'AllocateFairly',
                category: 'quality',
                group: 'district',
            },
            {
                title: 'Provide guidance and support to schools on funding and sustainability',
                handle: 'Supports',
                category: 'support',
                group: 'district',
            },
        ],
    },
    entity_quality_impact: {
        id: 7,
        handle: 'entity_quality_impact',
        title: 'Impact',
        score: '',
        indicators: [
            {
                title: 'Document impact on educational outcomes',
                handle: 'DocumentedEducational',
                group: 'school',
            },
            {
                title: 'Document impact of social, emotional, and behavioral outcomes',
                handle: 'DocumentedSocial',
                group: 'school',
            },
            {
                title: 'Disaggregate student mental health service and support data to examine student-level outcomes',
                handle: 'DisaggregateData',
                group: 'school',
            },
            {
                title: 'Document and broadly report the impact of your comprehensive school mental health system',
                handle: 'Report',
                group: 'school',
            },
            {
                title: 'Document impact on educational outcomes',
                handle: 'DocumentedImpactEducational',
                group: 'districtReporting',
            },
            {
                title: 'Document impact of social, emotional, and behavioral outcomes',
                handle: 'DocumentedImpactSocial',
                group: 'districtReporting',
            },
            {
                title: 'Disaggregate student mental health service and support data to examine student-level outcomes',
                handle: 'entityDisaggregateData',
                group: 'districtReporting',
            },
            {
                title: 'Document and broadly report the impact of your comprehensive school mental health system',
                handle: 'entityImpactReport',
                group: 'districtReporting',
            },
            {
                title: 'Establish and disseminate written, standard policies and procedures',
                handle: 'entityEstablishPolicies',
                group: 'district',
            },
            {
                title: 'Support implementation',
                handle: 'entitySupportImplementation',
                group: 'district',
            },
            {
                title: 'Monitor implementation',
                handle: 'entityMonitorDocumentation',
                group: 'district',
            },
            {
                title: 'Assess and monitor entity supports',
                handle: 'entitySupports',
                group: 'district',
            },
        ],
    },
};

export const OWBIscoringSchool = {
    school_owbi_workclimate: {
        id: 9,
        handle: 'school_owbi_workclimate',
        title: 'Work Climate & Environment',
        score: '',
        indicators: [
            {
                title: 'Space is well-lit and maintained',
                handle: 'Space',
                group: 'school',
            },
            {
                title: 'Employees have adequate resources',
                handle: 'Resources',
                group: 'school',
            },
            {
                title: 'Employee rights and well-being info posted',
                handle: 'PostedInfo',
                group: 'school',
            },
            {
                title: 'No-tolerance sexual/other harassment policy',
                handle: 'Policy',
                group: 'school',
            },
            {
                title: 'Formal, confidential grievance/complaint process',
                handle: 'Process',
                group: 'school',
            },
            {
                title: 'Avoids irrelevant or inefficient activities',
                handle: 'Avoids',
                group: 'school',
            },
            {
                title: 'Work delegated effectively',
                handle: 'Delegate',
                group: 'school',
            },
            {
                title: 'Healthy boundaries',
                handle: 'Boundaries',
                group: 'school',
            },
        ],
    },
    school_owbi_input: {
        id: 10,
        handle: 'school_owbi_input',
        title: 'Input, Flexibility & Autonomy',
        score: '',
        indicators: [
            {
                title: 'Clear process for giving feedback',
                handle: 'ClearProcess',
                group: 'school',
            },
            {
                title: 'Incorporates feedback into decisions',
                handle: 'UseFeedback',
                group: 'school',
            },
            {
                title: 'Employee involvement in policy and procedure',
                handle: 'EmployeePolicy',
                group: 'school',
            },
            {
                title: 'Employees empowered to share concerns',
                handle: 'EmployeeConcerns',
                group: 'school',
            },
            {
                title: 'Employees empowered to make suggestions',
                handle: 'EmployeeSuggestions',
                group: 'school',
            },
            {
                title: 'Employee autonomy supported',
                handle: 'EmployeeAutonomy',
                group: 'school',
            },
            {
                title: 'Employee given autonomy to self-manage',
                handle: 'EmployeeSelfManage',
                group: 'school',
            },
            {
                title: 'Individual needs and responsibilities accommodated',
                handle: 'Accomodate',
                group: 'school',
            },
        ],
    },
    school_owbi_development: {
        id: 11,
        handle: 'school_owbi_development',
        title: 'Professional Development and Recognition',
        score: '',
        indicators: [
            {
                title: 'Job-related training',
                handle: 'Training',
                group: 'school',
            },
            {
                title: 'Support for helpful trainings',
                handle: 'Support',
                group: 'school',
            },
            {
                title: 'Skills and education for advancement',
                handle: 'Skills',
                group: 'school',
            },
            {
                title: 'Leadership development training',
                handle: 'Leadership',
                group: 'school',
            },
            {
                title: 'Perks for quality performance',
                handle: 'Perks',
                group: 'school',
            },
            {
                title: 'Celebrates professional accomplishments',
                handle: 'Celebrates',
                group: 'school',
            },
            {
                title: 'Celebrates personal milestones and successes',
                handle: 'Milestones',
                group: 'school',
            },
            {
                title: 'Recognizes personal passions',
                handle: 'Recognize',
                group: 'school',
            },
        ],
    },
    school_owbi_org_support: {
        id: 12,
        handle: 'school_owbi_org_support',
        title: 'Organizational and Supervisory Support',
        score: '',
        indicators: [
            {
                title: 'Clearly defined roles and responsibilities',
                handle: 'DefinedRoles',
                group: 'school',
            },
            {
                title: 'Sufficient staff-to-student ratios',
                handle: 'Ratio',
                group: 'school',
            },
            {
                title: 'Administrative support',
                handle: 'AdminSupport',
                group: 'school',
            },
            {
                title: 'Sufficient, timely, relevant supervision',
                handle: 'Supervision',
                group: 'school',
            },
            {
                title: 'Support for instructional issues',
                handle: 'Instructional',
                group: 'school',
            },
            {
                title: 'Support for student-related issues',
                handle: 'StudentIssues',
                group: 'school',
            },
            {
                title: 'Clear and fair evaluation procedure',
                handle: 'Evaluation',
                group: 'school',
            },
            {
                title: 'Comfort discussing concerns without consequences',
                handle: 'Comfort',
                group: 'school',
            },
        ],
    },
    school_owbi_selfcare: {
        id: 13,
        handle: 'school_owbi_selfcare',
        title: 'Self-Care',
        score: '',
        indicators: [
            {
                title: 'Education and training on stress and well-being',
                handle: 'Education',
                group: 'school',
            },
            {
                title: 'Time and encouragement for self-care/compassion',
                handle: 'Time',
                group: 'school',
            },
            {
                title: 'Available and encouraged EAP',
                handle: 'EAP',
                group: 'school',
            },
            {
                title: 'Discuss stress and self-care in supervision',
                handle: 'Discuss',
                group: 'school',
            },
            {
                title: 'Opportunities for quiet time',
                handle: 'Quiet',
                group: 'school',
            },
            {
                title: 'Sufficient time for breaks',
                handle: 'Breaks',
                group: 'school',
            },
            {
                title: 'Encouraged use of sick and vacation time',
                handle: 'Vacation',
                group: 'school',
            },
            {
                title: 'Measurement of employee well-being and self-care',
                handle: 'Measure',
                group: 'school',
            },
        ],
    },
    school_owbi_deia: {
        id: 14,
        handle: 'school_owbi_deia',
        title: 'Diversity, Equity, Inclusion, Access (DEIA)',
        score: '',
        indicators: [
            {
                title: 'Clear definition of DEIA',
                handle: 'ClearDefinition',
                group: 'school',
            },
            {
                title: 'DEIA information posted visibly',
                handle: 'VisibleInfo',
                group: 'school',
            },
            {
                title: 'Continuous improvement of DEIA policies and practices',
                handle: 'QualityImprovement',
                group: 'school',
            },
            {
                title: 'Employee training on DEIA',
                handle: 'EmployeeTraining',
                group: 'school',
            },
            {
                title: 'Equitable decision-making',
                handle: 'EquitableDecisions',
                group: 'school',
            },
            {
                title: 'Clear feedback process for DEIA matters',
                handle: 'ClearFeedback',
                group: 'school',
            },
            {
                title: 'Culturally relevant instruction, supports, and services',
                handle: 'CulturallyRelevant',
                group: 'school',
            },
            {
                title: 'Feeling of acceptance and respect',
                handle: 'EmployeesAccepted',
                group: 'school',
            },
        ],
    },
    school_owbi_purpose: {
        id: 15,
        handle: 'school_owbi_purpose',
        title: 'Purpose and Meaningfulness',
        score: '',
        indicators: [
            {
                title: 'Identify and align personal values with work',
                handle: 'PersonalValues',
                group: 'school',
            },
            {
                title: 'Proud to work at school',
                handle: 'EmployeeProud',
                group: 'school',
            },
            {
                title: 'Assignments/responsibilities match interests/strengths',
                handle: 'EmployeeStrengths',
                group: 'school',
            },
            {
                title: 'Personally meaningful tasks and activities',
                handle: 'EmployeeMeaningful',
                group: 'school',
            },
            {
                title: 'Derive sense of purpose from careers',
                handle: 'EmployeePurpose',
                group: 'school',
            },
            {
                title: 'Opportunities to identify professional goals',
                handle: 'EmployeeGoals',
                group: 'school',
            },
            {
                title: 'Work has meaningful impact',
                handle: 'EmployeeImpact',
                group: 'school',
            },
            {
                title: 'Recognize value and unique contributions',
                handle: 'EmployeeMission',
                group: 'school',
            },
        ],
    },
    school_owbi_qol: {
        id: 16,
        handle: 'school_owbi_qol',
        title: 'Professional Quality of Life',
        score: '',
        indicators: [
            {
                title: 'Reasonable, achievable workload',
                handle: 'Reasonable',
                group: 'school',
            },
            {
                title: 'Training and skills well utilized',
                handle: 'Utilized',
                group: 'school',
            },
            {
                title: 'Tasks make a difference',
                handle: 'Difference',
                group: 'school',
            },
            {
                title: 'Happy employees',
                handle: 'Happy',
                group: 'school',
            },
            {
                title: 'Enjoy people they work with',
                handle: 'Enjoy',
                group: 'school',
            },
            {
                title: 'Sense of collegiality and teamwork',
                handle: 'Teamwork',
                group: 'school',
            },
            {
                title: 'Encouragement for new ideas and innovations',
                handle: 'NewIdeas',
                group: 'school',
            },
            {
                title: 'Work-life boundaries encouraged',
                handle: 'Boundaries',
                group: 'school',
            },
        ],
    },
};

export const OWBIscoringDistrict = {
    district_owbi_workclimate: {
        id: 9,
        handle: 'district_owbi_workclimate',
        title: 'Work Climate & Environment',
        score: '',
        indicators: [
            {
                title: 'Space is well-lit and maintained',
                handle: 'Space',
                group: 'school',
            },
            {
                title: 'Employees have adequate resources',
                handle: 'Resources',
                group: 'school',
            },
            {
                title: 'Employee rights and well-being info posted',
                handle: 'PostedInfo',
                group: 'school',
            },
            {
                title: 'No-tolerance sexual/other harassment policy',
                handle: 'Policy',
                group: 'school',
            },
            {
                title: 'Formal, confidential grievance/complaint process',
                handle: 'Process',
                group: 'school',
            },
            {
                title: 'Avoids irrelevant or inefficient activities',
                handle: 'Avoids',
                group: 'school',
            },
            {
                title: 'Work delegated effectively',
                handle: 'Delegate',
                group: 'school',
            },
            {
                title: 'Healthy boundaries',
                handle: 'Boundaries',
                group: 'school',
            },
        ],
    },
    district_owbi_input: {
        id: 10,
        handle: 'district_owbi_input',
        title: 'Input, Flexibility & Autonomy',
        score: '',
        indicators: [
            {
                title: 'Clear process for giving feedback',
                handle: 'ClearProcess',
                group: 'school',
            },
            {
                title: 'Incorporates feedback into decisions',
                handle: 'UseFeedback',
                group: 'school',
            },
            {
                title: 'Employee involvement in policy and procedure',
                handle: 'EmployeePolicy',
                group: 'school',
            },
            {
                title: 'Employees empowered to share concerns',
                handle: 'EmployeeConcerns',
                group: 'school',
            },
            {
                title: 'Employees empowered to make suggestions',
                handle: 'EmployeeSuggestions',
                group: 'school',
            },
            {
                title: 'Employee autonomy supported',
                handle: 'EmployeeAutonomy',
                group: 'school',
            },
            {
                title: 'Employee given autonomy to self-manage',
                handle: 'EmployeeSelfManage',
                group: 'school',
            },
            {
                title: 'Individual needs and responsibilities accommodated',
                handle: 'Accomodate',
                group: 'school',
            },
        ],
    },
    district_owbi_development: {
        id: 11,
        handle: 'district_owbi_development',
        title: 'Professional Development and Recognition',
        score: '',
        indicators: [
            {
                title: 'Job-related training',
                handle: 'Training',
                group: 'school',
            },
            {
                title: 'Support for helpful trainings',
                handle: 'Support',
                group: 'school',
            },
            {
                title: 'Skills and education for advancement',
                handle: 'Skills',
                group: 'school',
            },
            {
                title: 'Leadership development training',
                handle: 'Leadership',
                group: 'school',
            },
            {
                title: 'Perks for quality performance',
                handle: 'Perks',
                group: 'school',
            },
            {
                title: 'Celebrates professional accomplishments',
                handle: 'Celebrates',
                group: 'school',
            },
            {
                title: 'Celebrates personal milestones and successes',
                handle: 'Milestones',
                group: 'school',
            },
            {
                title: 'Recognizes personal passions',
                handle: 'Recognize',
                group: 'school',
            },
        ],
    },
    district_owbi_org_support: {
        id: 12,
        handle: 'district_owbi_org_support',
        title: 'Organizational and Supervisory Support',
        score: '',
        indicators: [
            {
                title: 'Clearly defined roles and responsibilities',
                handle: 'DefinedRoles',
                group: 'school',
            },
            {
                title: 'Sufficient staff-to-student ratios',
                handle: 'Ratio',
                group: 'school',
            },
            {
                title: 'Administrative support',
                handle: 'AdminSupport',
                group: 'school',
            },
            {
                title: 'Sufficient, timely, relevant supervision',
                handle: 'Supervision',
                group: 'school',
            },
            {
                title: 'Support for instructional issues',
                handle: 'Instructional',
                group: 'school',
            },
            {
                title: 'Support for student-related issues',
                handle: 'StudentIssues',
                group: 'school',
            },
            {
                title: 'Clear and fair evaluation procedure',
                handle: 'Evaluation',
                group: 'school',
            },
            {
                title: 'Comfort discussing concerns without consequences',
                handle: 'Comfort',
                group: 'school',
            },
        ],
    },
    district_owbi_selfcare: {
        id: 13,
        handle: 'district_owbi_selfcare',
        title: 'Self-Care',
        score: '',
        indicators: [
            {
                title: 'Education and training on stress and well-being',
                handle: 'Education',
                group: 'school',
            },
            {
                title: 'Time and encouragement for self-care/compassion',
                handle: 'Time',
                group: 'school',
            },
            {
                title: 'Available and encouraged EAP',
                handle: 'EAP',
                group: 'school',
            },
            {
                title: 'Discuss stress and self-care in supervision',
                handle: 'Discuss',
                group: 'school',
            },
            {
                title: 'Opportunities for quiet time',
                handle: 'Quiet',
                group: 'school',
            },
            {
                title: 'Sufficient time for breaks',
                handle: 'Breaks',
                group: 'school',
            },
            {
                title: 'Encouraged use of sick and vacation time',
                handle: 'Vacation',
                group: 'school',
            },
            {
                title: 'Measurement of employee well-being and self-care',
                handle: 'Measure',
                group: 'school',
            },
        ],
    },
    district_owbi_deia: {
        id: 14,
        handle: 'district_owbi_deia',
        title: 'Diversity, Equity, Inclusion, Access (DEIA)',
        score: '',
        indicators: [
            {
                title: 'Clear definition of DEIA',
                handle: 'ClearDefinition',
                group: 'school',
            },
            {
                title: 'DEIA information posted visibly',
                handle: 'VisibleInfo',
                group: 'school',
            },
            {
                title: 'Continuous improvement of DEIA policies and practices',
                handle: 'QualityImprovement',
                group: 'school',
            },
            {
                title: 'Employee training on DEIA',
                handle: 'EmployeeTraining',
                group: 'school',
            },
            {
                title: 'Equitable decision-making',
                handle: 'EquitableDecisions',
                group: 'school',
            },
            {
                title: 'Clear feedback process for DEIA matters',
                handle: 'ClearFeedback',
                group: 'school',
            },
            {
                title: 'Culturally relevant instruction, supports, and services',
                handle: 'CulturallyRelevant',
                group: 'school',
            },
            {
                title: 'Feeling of acceptance and respect',
                handle: 'EmployeesAccepted',
                group: 'school',
            },
        ],
    },
    district_owbi_purpose: {
        id: 15,
        handle: 'district_owbi_purpose',
        title: 'Purpose and Meaningfulness',
        score: '',
        indicators: [
            {
                title: 'Identify and align personal values with work',
                handle: 'PersonalValues',
                group: 'school',
            },
            {
                title: 'Proud to work at school',
                handle: 'EmployeeProud',
                group: 'school',
            },
            {
                title: 'Assignments/responsibilities match interests/strengths',
                handle: 'EmployeeStrengths',
                group: 'school',
            },
            {
                title: 'Personally meaningful tasks and activities',
                handle: 'EmployeeMeaningful',
                group: 'school',
            },
            {
                title: 'Derive sense of purpose from careers',
                handle: 'EmployeePurpose',
                group: 'school',
            },
            {
                title: 'Opportunities to identify professional goals',
                handle: 'EmployeeGoals',
                group: 'school',
            },
            {
                title: 'Work has meaningful impact',
                handle: 'EmployeeImpact',
                group: 'school',
            },
            {
                title: 'Recognize value and unique contributions',
                handle: 'EmployeeMission',
                group: 'school',
            },
        ],
    },
    district_owbi_qol: {
        id: 16,
        handle: 'district_owbi_qol',
        title: 'Professional Quality of Life',
        score: '',
        indicators: [
            {
                title: 'Reasonable, achievable workload',
                handle: 'Reasonable',
                group: 'school',
            },
            {
                title: 'Training and skills well utilized',
                handle: 'Utilized',
                group: 'school',
            },
            {
                title: 'Tasks make a difference',
                handle: 'Difference',
                group: 'school',
            },
            {
                title: 'Happy employees',
                handle: 'Happy',
                group: 'school',
            },
            {
                title: 'Enjoy people they work with',
                handle: 'Enjoy',
                group: 'school',
            },
            {
                title: 'Sense of collegiality and teamwork',
                handle: 'Teamwork',
                group: 'school',
            },
            {
                title: 'Encouragement for new ideas and innovations',
                handle: 'NewIdeas',
                group: 'school',
            },
            {
                title: 'Work-life boundaries encouraged',
                handle: 'Boundaries',
                group: 'school',
            },
        ],
    },
};

export const OWBIscoringEntity = {
    entity_owbi_workclimate: {
        id: 9,
        handle: 'entity_owbi_workclimate',
        title: 'Work Climate & Environment',
        score: '',
        indicators: [
            {
                title: 'Space is well-lit and maintained',
                handle: 'Space',
                group: 'school',
            },
            {
                title: 'Employees have adequate resources',
                handle: 'Resources',
                group: 'school',
            },
            {
                title: 'Employee rights and well-being info posted',
                handle: 'PostedInfo',
                group: 'school',
            },
            {
                title: 'No-tolerance sexual/other harassment policy',
                handle: 'Policy',
                group: 'school',
            },
            {
                title: 'Formal, confidential grievance/complaint process',
                handle: 'Process',
                group: 'school',
            },
            {
                title: 'Avoids irrelevant or inefficient activities',
                handle: 'Avoids',
                group: 'school',
            },
            {
                title: 'Work delegated effectively',
                handle: 'Delegate',
                group: 'school',
            },
            {
                title: 'Healthy boundaries',
                handle: 'Boundaries',
                group: 'school',
            },
        ],
    },
    entity_owbi_input: {
        id: 10,
        handle: 'entity_owbi_input',
        title: 'Input, Flexibility & Autonomy',
        score: '',
        indicators: [
            {
                title: 'Clear process for giving feedback',
                handle: 'ClearProcess',
                group: 'school',
            },
            {
                title: 'Incorporates feedback into decisions',
                handle: 'UseFeedback',
                group: 'school',
            },
            {
                title: 'Employee involvement in policy and procedure',
                handle: 'EmployeePolicy',
                group: 'school',
            },
            {
                title: 'Employees empowered to share concerns',
                handle: 'EmployeeConcerns',
                group: 'school',
            },
            {
                title: 'Employees empowered to make suggestions',
                handle: 'EmployeeSuggestions',
                group: 'school',
            },
            {
                title: 'Employee autonomy supported',
                handle: 'EmployeeAutonomy',
                group: 'school',
            },
            {
                title: 'Employee given autonomy to self-manage',
                handle: 'EmployeeSelfManage',
                group: 'school',
            },
            {
                title: 'Individual needs and responsibilities accommodated',
                handle: 'Accomodate',
                group: 'school',
            },
        ],
    },
    entity_owbi_development: {
        id: 11,
        handle: 'entity_owbi_development',
        title: 'Professional Development and Recognition',
        score: '',
        indicators: [
            {
                title: 'Job-related training',
                handle: 'Training',
                group: 'school',
            },
            {
                title: 'Support for helpful trainings',
                handle: 'Support',
                group: 'school',
            },
            {
                title: 'Skills and education for advancement',
                handle: 'Skills',
                group: 'school',
            },
            {
                title: 'Leadership development training',
                handle: 'Leadership',
                group: 'school',
            },
            {
                title: 'Perks for quality performance',
                handle: 'Perks',
                group: 'school',
            },
            {
                title: 'Celebrates professional accomplishments',
                handle: 'Celebrates',
                group: 'school',
            },
            {
                title: 'Celebrates personal milestones and successes',
                handle: 'Milestones',
                group: 'school',
            },
            {
                title: 'Recognizes personal passions',
                handle: 'Recognize',
                group: 'school',
            },
        ],
    },
    entity_owbi_org_support: {
        id: 12,
        handle: 'entity_owbi_org_support',
        title: 'Organizational and Supervisory Support',
        score: '',
        indicators: [
            {
                title: 'Clearly defined roles and responsibilities',
                handle: 'DefinedRoles',
                group: 'school',
            },
            {
                title: 'Sufficient staff-to-student ratios',
                handle: 'Ratio',
                group: 'school',
            },
            {
                title: 'Administrative support',
                handle: 'AdminSupport',
                group: 'school',
            },
            {
                title: 'Sufficient, timely, relevant supervision',
                handle: 'Supervision',
                group: 'school',
            },
            {
                title: 'Support for instructional issues',
                handle: 'Instructional',
                group: 'school',
            },
            {
                title: 'Support for student-related issues',
                handle: 'StudentIssues',
                group: 'school',
            },
            {
                title: 'Clear and fair evaluation procedure',
                handle: 'Evaluation',
                group: 'school',
            },
            {
                title: 'Comfort discussing concerns without consequences',
                handle: 'Comfort',
                group: 'school',
            },
        ],
    },
    entity_owbi_selfcare: {
        id: 13,
        handle: 'entity_owbi_selfcare',
        title: 'Self-Care',
        score: '',
        indicators: [
            {
                title: 'Education and training on stress and well-being',
                handle: 'Education',
                group: 'school',
            },
            {
                title: 'Time and encouragement for self-care/compassion',
                handle: 'Time',
                group: 'school',
            },
            {
                title: 'Available and encouraged EAP',
                handle: 'EAP',
                group: 'school',
            },
            {
                title: 'Discuss stress and self-care in supervision',
                handle: 'Discuss',
                group: 'school',
            },
            {
                title: 'Opportunities for quiet time',
                handle: 'Quiet',
                group: 'school',
            },
            {
                title: 'Sufficient time for breaks',
                handle: 'Breaks',
                group: 'school',
            },
            {
                title: 'Encouraged use of sick and vacation time',
                handle: 'Vacation',
                group: 'school',
            },
            {
                title: 'Measurement of employee well-being and self-care',
                handle: 'Measure',
                group: 'school',
            },
        ],
    },
    entity_owbi_deia: {
        id: 14,
        handle: 'entity_owbi_deia',
        title: 'Diversity, Equity, Inclusion, Access (DEIA)',
        score: '',
        indicators: [
            {
                title: 'Clear definition of DEIA',
                handle: 'ClearDefinition',
                group: 'school',
            },
            {
                title: 'DEIA information posted visibly',
                handle: 'VisibleInfo',
                group: 'school',
            },
            {
                title: 'Continuous improvement of DEIA policies and practices',
                handle: 'QualityImprovement',
                group: 'school',
            },
            {
                title: 'Employee training on DEIA',
                handle: 'EmployeeTraining',
                group: 'school',
            },
            {
                title: 'Equitable decision-making',
                handle: 'EquitableDecisions',
                group: 'school',
            },
            {
                title: 'Clear feedback process for DEIA matters',
                handle: 'ClearFeedback',
                group: 'school',
            },
            {
                title: 'Culturally relevant instruction, supports, and services',
                handle: 'CulturallyRelevant',
                group: 'school',
            },
            {
                title: 'Feeling of acceptance and respect',
                handle: 'EmployeesAccepted',
                group: 'school',
            },
        ],
    },
    entity_owbi_purpose: {
        id: 15,
        handle: 'entity_owbi_purpose',
        title: 'Purpose and Meaningfulness',
        score: '',
        indicators: [
            {
                title: 'Identify and align personal values with work',
                handle: 'PersonalValues',
                group: 'school',
            },
            {
                title: 'Proud to work at school',
                handle: 'EmployeeProud',
                group: 'school',
            },
            {
                title: 'Assignments/responsibilities match interests/strengths',
                handle: 'EmployeeStrengths',
                group: 'school',
            },
            {
                title: 'Personally meaningful tasks and activities',
                handle: 'EmployeeMeaningful',
                group: 'school',
            },
            {
                title: 'Derive sense of purpose from careers',
                handle: 'EmployeePurpose',
                group: 'school',
            },
            {
                title: 'Opportunities to identify professional goals',
                handle: 'EmployeeGoals',
                group: 'school',
            },
            {
                title: 'Work has meaningful impact',
                handle: 'EmployeeImpact',
                group: 'school',
            },
            {
                title: 'Recognize value and unique contributions',
                handle: 'EmployeeMission',
                group: 'school',
            },
        ],
    },
    entity_owbi_qol: {
        id: 16,
        handle: 'entity_owbi_qol',
        title: 'Professional Quality of Life',
        score: '',
        indicators: [
            {
                title: 'Reasonable, achievable workload',
                handle: 'Reasonable',
                group: 'school',
            },
            {
                title: 'Training and skills well utilized',
                handle: 'Utilized',
                group: 'school',
            },
            {
                title: 'Tasks make a difference',
                handle: 'Difference',
                group: 'school',
            },
            {
                title: 'Happy employees',
                handle: 'Happy',
                group: 'school',
            },
            {
                title: 'Enjoy people they work with',
                handle: 'Enjoy',
                group: 'school',
            },
            {
                title: 'Sense of collegiality and teamwork',
                handle: 'Teamwork',
                group: 'school',
            },
            {
                title: 'Encouragement for new ideas and innovations',
                handle: 'NewIdeas',
                group: 'school',
            },
            {
                title: 'Work-life boundaries encouraged',
                handle: 'Boundaries',
                group: 'school',
            },
        ],
    },
};

export const CAREscoringSchool = {
    school_care_teaming: {
        id: 10,
        handle: 'school_care_teaming',
        title: 'Teaming and Collaboration',
        score: '',
        indicators: [
            {
                title: 'Implement and assess CARE policies and practices',
                handle: 'Policies',
                group: 'school',
            },
            {
                title: 'Collect and use information from stakeholders to inform decisions',
                handle: 'Decisions',
                group: 'school',
            },
            {
                title: 'Review data by group and address disparities',
                handle: 'Disparities',
                group: 'school',
            },
            {
                title: 'Share decision-making power with families and the community ',
                handle: 'Share',
                group: 'school',
            },
            {
                title: 'Hire staff that reflect diversity of local community',
                handle: 'DiverseStaff',
                group: 'school',
            },
            {
                title: 'Hire staff committed to CARE principles',
                handle: 'CareStaff',
                group: 'school',
            },
            {
                title: 'Provide space and opportunity for staff to connect',
                handle: 'Connect',
                group: 'school',
            },
            {
                title: 'Spend funds equitably',
                handle: 'Funds',
                group: 'school',
            },
            {
                title: 'Partner with community to create sustainable programs',
                handle: 'Programs',
                group: 'school',
            },
        ],
    },
    school_care_climate: {
        id: 11,
        handle: 'school_care_climate',
        title: 'School Climate',
        score: '',
        indicators: [
            {
                title: 'Foster a safe and welcoming environment',
                handle: 'Safety',
                group: 'school',
            },
            {
                title: 'Provide additional support for academics and well-being',
                handle: 'Support',
                group: 'school',
            },
            {
                title: 'Provide equitable and barrier-free access',
                handle: 'Access',
                group: 'school',
            },
            {
                title: 'Develop students’ positive self-identities',
                handle: 'SelfIdentity',
                group: 'school',
            },
            {
                title: 'Adapt communication strategies for diverse styles and abilities',
                handle: 'Communication',
                group: 'school',
            },
            {
                title: 'Promote connection to community',
                handle: 'Community',
                group: 'school',
            },
        ],
    },
    school_care_discipline: {
        id: 12,
        handle: 'school_care_discipline',
        title: 'School Discipline',
        score: '',
        indicators: [
            {
                title: 'Collaborate with students and families to form discipline policies',
                handle: 'Collaborate',
                group: 'school',
            },
            {
                title: 'Promote socioemotional learning for students and staff',
                handle: 'SocioEmotional',
                group: 'school',
            },
            {
                title: 'Implement trauma-informed policies',
                handle: 'Policies',
                group: 'school',
            },
            {
                title: 'Eliminate use of harmful discipline practices',
                handle: 'Harmful',
                group: 'school',
            },
            {
                title: 'Identify alternative strategies',
                handle: 'Strategies',
                group: 'school',
            },
            {
                title: 'Monitor and review discipline data',
                handle: 'MonitorDiscipline',
                group: 'school',
            },
            {
                title: 'Monitor and review special education data',
                handle: 'MonitorSplEd',
                group: 'school',
            },
            {
                title: 'Participate in training on how bias impacts student discipline in general',
                handle: 'BiasGeneral',
                group: 'school',
            },
            {
                title: 'Participate in training on how bias impacts student discipline at your school',
                handle: 'BiasSchool',
                group: 'school',
            },
            {
                title: 'Participate in training on classroom management skills',
                handle: 'Management',
                group: 'school',
            },
        ],
    },
    school_care_curricula: {
        id: 13,
        handle: 'school_care_curricula',
        title: 'Curricula and Teaching Practices',
        score: '',
        indicators: [
            {
                title: 'Use student and family feedback in curricula development',
                handle: 'Feedback',
                group: 'school',
            },
            {
                title: "Present content that connects to students' daily lives",
                handle: 'Content',
                group: 'school',
            },
            {
                title: 'Demonstrate understanding of local culture',
                handle: 'Culture',
                group: 'school',
            },
            {
                title: 'Ensure diverse representation in content',
                handle: 'DiverseContent',
                group: 'school',
            },
            {
                title: 'Ensure diverse representation in classroom materials',
                handle: 'DiverseMaterials',
                group: 'school',
            },
            {
                title: 'Address the impact of power and oppression in education',
                handle: 'Education',
                group: 'school',
            },
            {
                title: 'Provide diverse learning opportunities',
                handle: 'Learning',
                group: 'school',
            },
            {
                title: 'Use culturally responsive evaluation procedures',
                handle: 'Evaluation',
                group: 'school',
            },
            {
                title: 'Provide access to diverse materials to educators',
                handle: 'Educators',
                group: 'school',
            },
        ],
    },
    school_care_prof_dev: {
        id: 14,
        handle: 'school_care_prof_dev',
        title: 'Professional Development',
        score: '',
        indicators: [
            {
                title: 'Engage in individual development',
                handle: 'Development',
                group: 'school',
            },
            {
                title: 'Provide professional development in CARE practices',
                handle: 'Practices',
                group: 'school',
            },
            {
                title: 'Provide professional development in family and community partnerships',
                handle: 'Partnerships',
                group: 'school',
            },
            {
                title: 'Evaluate professional development activities ',
                handle: 'Activities',
                group: 'school',
            },
        ],
    },
};

export const CAREscoringDistrict = {
    district_care_teaming: {
        id: 10,
        handle: 'district_care_teaming',
        title: 'Teaming and Collaboration',
        score: '',
        indicators: [
            {
                // 1
                title: 'Collect and use information from stakeholders to inform decisions',
                handle: 'Decisions',
                group: 'district',
            },
            {
                // 2
                title: 'Review data by group and address disparities',
                handle: 'Disparities',
                group: 'district',
            },
            {
                // 3
                title: 'Share decision-making power with families and the community ',
                handle: 'Share',
                group: 'district',
            },
            {
                // 4
                title: 'Hire people that reflect diversity of local community',
                handle: 'DiverseStaff',
                group: 'district',
            },
            {
                // 5
                title: 'Hire people committed to CARE principles',
                handle: 'CareStaff',
                group: 'district',
            },
            {
                // 6
                title: 'Provide space and opportunity for staff to connect',
                handle: 'Connect',
                group: 'district',
            },
            {
                // 7
                title: 'Spend funds equitably',
                handle: 'Funds',
                group: 'district',
            },
            {
                // 8
                title: 'Partner with community to create sustainable programs',
                handle: 'Programs',
                group: 'district',
            },
            {
                // 9
                title: 'Provide staff to coordinate and support districtwide efforts',
                handle: 'Policies',
                group: 'district',
            },
            {
                // 10
                title: 'Establish and disseminate written policies and procedures',
                handle: 'EstablishPolicies',
                group: 'district',
            },
            {
                // 11
                title: 'Support implementation across district',
                handle: 'SupportsPolicies',
                group: 'district',
            },
            {
                // 12
                title: 'Monitor CARE policies and practices across district',
                handle: 'MonitorsPolicies',
                group: 'district',
            },
        ],
    },
    district_care_climate: {
        id: 11,
        handle: 'district_care_climate',
        title: 'School Climate',
        score: '',
        indicators: [
            {
                // 1
                title: 'Provides resources for a safe and welcoming environment',
                handle: 'Safety',
                group: 'district',
            },
            {
                // 2
                title: 'Provide additional support for academics and well-being',
                handle: 'Support',
                group: 'district',
            },
            {
                // 3
                title: 'Provide equitable and barrier-free access',
                handle: 'Access',
                group: 'district',
            },
            {
                // 4
                title: 'Provides resources to support student positive self-identity development',
                handle: 'SelfIdentity',
                group: 'district',
            },
            {
                // 5
                title: 'Adapt communication strategies for diverse styles and abilities',
                handle: 'Communication',
                group: 'district',
            },
            {
                // 6
                title: 'Promote connection to community',
                handle: 'Community',
                group: 'district',
            },
        ],
    },
    district_care_discipline: {
        id: 12,
        handle: 'district_care_discipline',
        title: 'School Discipline',
        score: '',
        indicators: [
            {
                // 1
                title: 'Collaborate with students and families to form discipline policies',
                handle: 'Collaborate',
                group: 'district',
            },
            {
                // 2
                title: 'Promote socioemotional learning for students and staff',
                handle: 'SocioEmotional',
                group: 'district',
            },
            {
                // 3
                title: 'Implement trauma-informed policies',
                handle: 'Policies',
                group: 'district',
            },
            {
                // 4
                title: 'Eliminate use of harmful discipline practices',
                handle: 'Harmful',
                group: 'district',
            },
            {
                // 5
                title: 'Identify alternative strategies',
                handle: 'Strategies',
                group: 'district',
            },
            {
                // 6
                title: 'Monitor and review discipline data',
                handle: 'MonitorDiscipline',
                group: 'district',
            },
            {
                // 7
                title: 'Monitor and review special education data',
                handle: 'MonitorSplEd',
                group: 'district',
            },
            {
                // 8
                title: 'Participate in training on how bias impacts student discipline in general',
                handle: 'BiasGeneral',
                group: 'district',
            },
            {
                // 9
                title: 'Participate in training on how bias impacts student discipline at your district',
                handle: 'BiasSchool',
                group: 'district',
            },
            {
                // 10
                title: 'Participate in training on classroom management skills',
                handle: 'Management',
                group: 'district',
            },
        ],
    },
    district_care_curricula: {
        id: 13,
        handle: 'district_care_curricula',
        title: 'Curricula and Teaching Practices',
        score: '',
        indicators: [
            {
                // 1
                title: 'Use student and family feedback in curricula development',
                handle: 'Feedback',
                group: 'district',
            },
            {
                // 2
                title: "Connect to students' daily lives",
                handle: 'Content',
                group: 'district',
            },
            {
                // 3
                title: 'Demonstrate understanding of local culture',
                handle: 'Culture',
                group: 'district',
            },
            {
                // 4
                title: 'Ensure diverse representation in content',
                handle: 'DiverseContent',
                group: 'district',
            },
            {
                // 5
                title: 'Ensure diverse representation in classroom materials',
                handle: 'DiverseMaterials',
                group: 'district',
            },
            {
                // 6
                title: 'Address the impact of power and oppression in education',
                handle: 'Education',
                group: 'district',
            },
            {
                // 7
                title: 'Use culturally responsive evaluation procedures',
                handle: 'Learning',
                group: 'district',
            },
            {
                // 8
                title: 'Provide access to diverse materials to educators',
                handle: 'Educators',
                group: 'district',
            },
        ],
    },
    district_care_prof_dev: {
        id: 14,
        handle: 'district_care_prof_dev',
        title: 'Professional Development',
        score: '',
        indicators: [
            {
                // 1
                title: 'Engage in individual development',
                handle: 'Development',
                group: 'district',
            },
            {
                // 2
                title: 'Provide professional development in CARE practices',
                handle: 'Practices',
                group: 'district',
            },
            {
                // 3
                title: 'Provide professional development in family and community partnerships',
                handle: 'Partnerships',
                group: 'district',
            },
            {
                // 4
                title: 'Evaluate professional development activities',
                handle: 'Activities',
                group: 'district',
            },
        ],
    },
};

export const CAREscoringEntity = {
    entity_care_teaming: {
        id: 10,
        handle: 'entity_care_teaming',
        title: 'Teaming and Collaboration',
        score: '',
        indicators: [
            {
                // 1
                title: 'Collect and use information from stakeholders to inform decisions',
                handle: 'Decisions',
                group: 'entity',
            },
            {
                // 2
                title: 'Review data by group and address disparities',
                handle: 'Disparities',
                group: 'entity',
            },
            {
                // 3
                title: 'Share decision-making power with families and the community ',
                handle: 'Share',
                group: 'entity',
            },
            {
                // 4
                title: 'Hire people that reflect diversity of local community',
                handle: 'DiverseStaff',
                group: 'entity',
            },
            {
                // 5
                title: 'Hire people committed to CARE principles',
                handle: 'CareStaff',
                group: 'entity',
            },
            {
                // 6
                title: 'Provide space and opportunity for staff to connect',
                handle: 'Connect',
                group: 'entity',
            },
            {
                // 7
                title: 'Spend funds equitably',
                handle: 'Funds',
                group: 'entity',
            },
            {
                // 8
                title: 'Partner with community to create sustainable programs',
                handle: 'Programs',
                group: 'entity',
            },
            {
                // 9
                title: 'Provide staff to coordinate and support entitywide efforts',
                handle: 'Policies',
                group: 'entity',
            },
            {
                // 10
                title: 'Establish and disseminate written policies and procedures',
                handle: 'EstablishPolicies',
                group: 'entity',
            },
            {
                // 11
                title: 'Support implementation across entity',
                handle: 'SupportsPolicies',
                group: 'entity',
            },
            {
                // 12
                title: 'Monitor CARE policies and practices across entity',
                handle: 'MonitorsPolicies',
                group: 'entity',
            },
        ],
    },
    entity_care_climate: {
        id: 11,
        handle: 'entity_care_climate',
        title: 'School Climate',
        score: '',
        indicators: [
            {
                // 1
                title: 'Provides resources for a safe and welcoming environment',
                handle: 'Safety',
                group: 'entity',
            },
            {
                // 2
                title: 'Provide additional support for academics and well-being',
                handle: 'Support',
                group: 'entity',
            },
            {
                // 3
                title: 'Provide equitable and barrier-free access',
                handle: 'Access',
                group: 'entity',
            },
            {
                // 4
                title: 'Provides resources to support student positive self-identity development',
                handle: 'SelfIdentity',
                group: 'entity',
            },
            {
                // 5
                title: 'Adapt communication strategies for diverse styles and abilities',
                handle: 'Communication',
                group: 'entity',
            },
            {
                // 6
                title: 'Promote connection to community',
                handle: 'Community',
                group: 'entity',
            },
        ],
    },
    entity_care_discipline: {
        id: 12,
        handle: 'entity_care_discipline',
        title: 'School Discipline',
        score: '',
        indicators: [
            {
                // 1
                title: 'Collaborate with students and families to form discipline policies',
                handle: 'Collaborate',
                group: 'entity',
            },
            {
                // 2
                title: 'Promote socioemotional learning for students and staff',
                handle: 'SocioEmotional',
                group: 'entity',
            },
            {
                // 3
                title: 'Implement trauma-informed policies',
                handle: 'Policies',
                group: 'entity',
            },
            {
                // 4
                title: 'Eliminate use of harmful discipline practices',
                handle: 'Harmful',
                group: 'entity',
            },
            {
                // 5
                title: 'Identify alternative strategies',
                handle: 'Strategies',
                group: 'entity',
            },
            {
                // 6
                title: 'Monitor and review discipline data',
                handle: 'MonitorDiscipline',
                group: 'entity',
            },
            {
                // 7
                title: 'Monitor and review special education data',
                handle: 'MonitorSplEd',
                group: 'entity',
            },
            {
                // 8
                title: 'Participate in training on how bias impacts student discipline in general',
                handle: 'BiasGeneral',
                group: 'entity',
            },
            {
                // 9
                title: 'Participate in training on how bias impacts student discipline at your entity',
                handle: 'BiasSchool',
                group: 'entity',
            },
            {
                // 10
                title: 'Participate in training on classroom management skills',
                handle: 'Management',
                group: 'entity',
            },
        ],
    },
    entity_care_curricula: {
        id: 13,
        handle: 'entity_care_curricula',
        title: 'Curricula and Teaching Practices',
        score: '',
        indicators: [
            {
                // 1
                title: 'Use student and family feedback in curricula development',
                handle: 'Feedback',
                group: 'entity',
            },
            {
                // 2
                title: "Connect to students' daily lives",
                handle: 'Content',
                group: 'entity',
            },
            {
                // 3
                title: 'Demonstrate understanding of local culture',
                handle: 'Culture',
                group: 'entity',
            },
            {
                // 4
                title: 'Ensure diverse representation in content',
                handle: 'DiverseContent',
                group: 'entity',
            },
            {
                // 5
                title: 'Ensure diverse representation in classroom materials',
                handle: 'DiverseMaterials',
                group: 'entity',
            },
            {
                // 6
                title: 'Address the impact of power and oppression in education',
                handle: 'Education',
                group: 'entity',
            },
            {
                // 7
                title: 'Use culturally responsive evaluation procedures',
                handle: 'Learning',
                group: 'entity',
            },
            {
                // 8
                title: 'Provide access to diverse materials to educators',
                handle: 'Educators',
                group: 'entity',
            },
        ],
    },
    entity_care_prof_dev: {
        id: 14,
        handle: 'entity_care_prof_dev',
        title: 'Professional Development',
        score: '',
        indicators: [
            {
                // 1
                title: 'Engage in individual development',
                handle: 'Development',
                group: 'entity',
            },
            {
                // 2
                title: 'Provide professional development in CARE practices',
                handle: 'Practices',
                group: 'entity',
            },
            {
                // 3
                title: 'Provide professional development in family and community partnerships',
                handle: 'Partnerships',
                group: 'entity',
            },
            {
                // 4
                title: 'Evaluate professional development activities',
                handle: 'Activities',
                group: 'entity',
            },
        ],
    },
};

export const groups = [
    { handle: 'school', title: 'School' },
    { handle: 'preschool', title: 'Preschool' },
    { handle: 'district', title: 'District' },
];

export const schoolCategories = [
    { handle: 'partners', title: 'Partners', flowHandle: 'district_quality_teaming' },
    { handle: 'structure', title: 'Structure/Process', flowHandle: 'district_quality_teaming' },
    { handle: 'data', title: 'Data', flowHandle: 'district_quality_teaming' },
    {
        handle: 'needsassessment',
        title: 'Needs Assessment',
        flowHandle: 'district_quality_needsassessment',
    },
    {
        handle: 'resourcemapping',
        title: 'Resource mapping and implementation',
        flowHandle: 'district_quality_needsassessment',
    },
    { handle: 'alignment', title: 'Alignment', flowHandle: 'district_quality_needsassessment' },

    {
        handle: 'services',
        title: 'Services and Supports',
        flowHandle: 'district_quality_MHpromotion',
    },
    {
        handle: 'implementation',
        title: 'Implementation',
        flowHandle: 'district_quality_MHpromotion',
    },

    {
        handle: 'services',
        title: 'Services and Supports',
        flowHandle: 'district_quality_earlyintervention',
    },
    {
        handle: 'implementation',
        title: 'Implementation',
        flowHandle: 'district_quality_earlyintervention',
    },

    { handle: 'funding', title: 'Funding / resources', flowHandle: 'district_quality_funding' },
    {
        handle: 'staffretention',
        title: 'Staff retention and Professional Development',
        flowHandle: 'district_quality_funding',
    },

    { handle: 'partners', title: 'Partners', flowHandle: 'school_quality_teaming' },
    { handle: 'structure', title: 'Structure/Process', flowHandle: 'school_quality_teaming' },
    { handle: 'data', title: 'Data', flowHandle: 'school_quality_teaming' },
    {
        handle: 'needsassessment',
        title: 'Needs Assessment',
        flowHandle: 'school_quality_needsassessment',
    },
    {
        handle: 'resourcemapping',
        title: 'Resource mapping and implementation',
        flowHandle: 'school_quality_needsassessment',
    },
    { handle: 'alignment', title: 'Alignment', flowHandle: 'school_quality_needsassessment' },

    {
        handle: 'services',
        title: 'Services and Supports',
        flowHandle: 'school_quality_MHpromotion',
    },
    {
        handle: 'implementation',
        title: 'Implementation',
        flowHandle: 'school_quality_MHpromotion',
    },

    {
        handle: 'services',
        title: 'Services and Supports',
        flowHandle: 'school_quality_earlyintervention',
    },
    {
        handle: 'implementation',
        title: 'Implementation',
        flowHandle: 'school_quality_earlyintervention',
    },

    { handle: 'funding', title: 'Funding / resources', flowHandle: 'school_quality_funding' },
    {
        handle: 'staffretention',
        title: 'Staff retention and Professional Development',
        flowHandle: 'school_quality_funding',
    },
    { handle: 'partners', title: 'Partners', flowHandle: 'entity_quality_teaming' },
    { handle: 'structure', title: 'Structure/Process', flowHandle: 'entity_quality_teaming' },
    { handle: 'data', title: 'Data', flowHandle: 'entity_quality_teaming' },
    {
        handle: 'needsassessment',
        title: 'Needs Assessment',
        flowHandle: 'entity_quality_needsassessment',
    },
    {
        handle: 'resourcemapping',
        title: 'Resource mapping and implementation',
        flowHandle: 'entity_quality_needsassessment',
    },
    { handle: 'alignment', title: 'Alignment', flowHandle: 'entity_quality_needsassessment' },

    {
        handle: 'services',
        title: 'Services and Supports',
        flowHandle: 'entity_quality_MHpromotion',
    },
    {
        handle: 'implementation',
        title: 'Implementation',
        flowHandle: 'entity_quality_MHpromotion',
    },

    {
        handle: 'services',
        title: 'Services and Supports',
        flowHandle: 'entity_quality_earlyintervention',
    },
    {
        handle: 'implementation',
        title: 'Implementation',
        flowHandle: 'entity_quality_earlyintervention',
    },

    { handle: 'funding', title: 'Funding / resources', flowHandle: 'entity_quality_funding' },
    {
        handle: 'staffretention',
        title: 'Staff retention and Professional Development',
        flowHandle: 'entity_quality_funding',
    },
];

export const districtCategories = [
    { handle: 'support', title: 'District Support', flowHandle: 'district_quality_teaming' },
    {
        handle: 'support',
        title: 'District Support',
        flowHandle: 'district_quality_needsassessment',
    },
    { handle: 'support', title: 'District Support', flowHandle: 'district_quality_MHpromotion' },
    {
        handle: 'support',
        title: 'District Support',
        flowHandle: 'district_quality_earlyintervention',
    },
    {
        handle: 'quality',
        title: 'District funding quality',
        flowHandle: 'district_quality_funding',
    },
    { handle: 'support', title: 'District Support', flowHandle: 'district_quality_funding' },
];
