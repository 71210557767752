import React from 'react';
import PropTypes from 'prop-types';
import { reduxForm, change } from 'redux-form';
import { FieldBlock, domOnlyProps } from 'cccisd-redux-form-helpers';
import ClickButton from 'cccisd-click-button';
import { connect } from 'react-redux';
import { getShapeFlowData } from 'js/reducers/shape.js';
import DomainsDropdown from './DomainsDropdown';
import UsersDropdown from './UsersDropdown';
import TimePeriodDropdown from './TimePeriodDropdown';
import { getDomainOptions, getUniqueUsers } from 'js/selectors/shape.js';
import style from './style.css';

import _xor from 'lodash/xor';

const fields = ['timePeriods', 'startDate', 'endDate', 'domain', 'enteredBy', 'reportType'];

export const validate = (values, props) => {
    const errors = {};

    if (values.timePeriods && values.timePeriods.length < 1) {
        errors.timePeriods = 'Reporting Period is required.';
    }
    if (!props.isAggregateReport) {
        if (!values.enteredBy || values.enteredBy.length < 1) {
            errors.enteredBy = 'Entered By is required.';
        }
    }
    if (!props.isAggregateReport) {
        if (!values.domain || values.domain.length < 1) {
            errors.domain = 'Domain is required.';
        }
    }
    return errors;
};

class ReportForm extends React.Component {
    static propTypes = {
        showErrorsImmediately: PropTypes.bool,
        handleSubmit: PropTypes.func,
        fields: PropTypes.object,
        submitting: PropTypes.bool,
        completedBy: PropTypes.array,
        flows: PropTypes.array,
        flowType: PropTypes.string,
        isAggregateReport: PropTypes.bool,
    };

    static defaultProps = {
        fields: {
            timePeriods: ['08/2019-11/2019'],
            domain: [],
            enteredBy: [],
            endDate: '2019-12',
            startDate: '2018-12',
            reportType: 'Average',
        },
    };

    componentDidUpdate = async prevProps => {
        const { startDate, endDate, timePeriods } = this.props.fields;
        if (!this.props.isAggregateReport) {
            if (_xor(prevProps.fields.timePeriods.value, timePeriods.value).length !== 0) {
                await this.props.getShapeFlowData(
                    this.props.groupId,
                    this.props.flowType,
                    startDate.value,
                    endDate.value,
                    timePeriods.value
                );
                this.props.dispatch(change('ReportForm', 'domain', []));
                this.props.dispatch(change('ReportForm', 'enteredBy', []));
            }
        }
    };

    onDomainsChange = () => {
        this.props.dispatch(change('ReportForm', 'enteredBy', []));
    };

    render() {
        const {
            handleSubmit,
            submitting,
            fields: { timePeriods, startDate, endDate, domain, enteredBy, reportType },
            flows,
            flowType,
            reportingPeriods,
        } = this.props;
        let shapeFlowData;
        if (flowType === 'trs') {
            shapeFlowData = this.props.trsFlowData;
        } else if (['owbi', 'owbiSchool', 'owbiDistrict', 'owbiEntity'].includes(flowType)) {
            shapeFlowData = this.props.owbiFlowData;
        } else if (['care', 'careSchool', 'careDistrict', 'careEntity'].includes(flowType)) {
            shapeFlowData = this.props.careFlowData;
        } else {
            shapeFlowData = this.props.mhqFlowData;
        }

        return (
            <form onSubmit={handleSubmit}>
                <div className={style.inputs}>
                    <FieldBlock field={timePeriods} label="Reporting Period">
                        <TimePeriodDropdown
                            field={timePeriods}
                            options={reportingPeriods}
                            label="Reporting Period"
                            getUniqueUsers={getUniqueUsers}
                            onDomainsChange={this.onDomainsChange}
                            onChange={this.onDomainsChange}
                        />
                    </FieldBlock>

                    {this.props.isAggregateReport ? (
                        <FieldBlock field={domain} label="Domain">
                            <DomainsDropdown
                                field={domain}
                                options={getDomainOptions(
                                    startDate.value,
                                    endDate.value,
                                    shapeFlowData,
                                    flows,
                                    this.props.isAggregateReport,
                                    this.props.flowType
                                )}
                                label="Domain"
                                getUniqueUsers={getUniqueUsers}
                                onDomainsChange={this.onDomainsChange}
                                onChange={this.onDomainsChange}
                            />
                        </FieldBlock>
                    ) : (
                        <FieldBlock field={domain} label="Domain">
                            <DomainsDropdown
                                field={domain}
                                options={getDomainOptions(startDate.value, endDate.value, shapeFlowData, flows)}
                                label="Domain"
                                getUniqueUsers={getUniqueUsers}
                                onDomainsChange={this.onDomainsChange}
                                onChange={this.onDomainsChange}
                            />
                        </FieldBlock>
                    )}

                    {!this.props.isAggregateReport && (
                        <FieldBlock field={enteredBy} label="Entered By">
                            <UsersDropdown
                                field={enteredBy}
                                options={getUniqueUsers(domain, domain.value, flowType, shapeFlowData)}
                                newCompletedBy={this.props.newCompletedBy}
                                label="User"
                            />
                        </FieldBlock>
                    )}
                    <FieldBlock field={reportType} label="Report Type">
                        <select {...domOnlyProps(reportType)} className="form-control">
                            <option value="Average">Average</option>
                            <option value="Last Completed">Last Completed</option>
                        </select>
                    </FieldBlock>
                    <div className="form-group" style={{ marginTop: '27px' }}>
                        <ClickButton
                            isLoading={submitting}
                            onClick={handleSubmit}
                            title="Generate Report"
                            className="btn btn-primary"
                        />
                    </div>
                </div>
            </form>
        );
    }
}

const mapStateToProps = (state, props) => {
    return {
        mhqFlowData: state.app.shape.mhqFlowData,
        trsFlowData: state.app.shape.trsFlowData,
        owbiFlowData: state.app.shape.owbiFlowData,
        careFlowData: state.app.shape.careFlowData,
        reportingPeriods: state.app.shape.reportingPeriods,
    };
};

export default reduxForm({
    form: 'ReportForm',
    fields,
    validate,
    overwriteOnInitialValuesChange: false,
})(connect(mapStateToProps, { getShapeFlowData })(ReportForm));
