import React from 'react';
import PropTypes from 'prop-types';
import { Page } from 'cccisd-laravel-report';
import { Html } from 'cccisd-wysiwyg';
import { schoolCategories, districtCategories } from 'js/selectors/scoringData.js';
import BarGraph from '../BarGraph';
import SubHeading from '../SubHeading';
import Screening from '../Screening';
import PreschoolScreening from '../PreschoolScreening';
import Impact from '../Impact';
import SinglePageReportSection from './SinglePageReportSection';
import TrsReportSection from './TrsReportSection';

import GaugeGreen from '../images/GaugeGreen.png';
import GaugeYellow from '../images/GaugeYellow.png';
import GaugeRed from '../images/GaugeRed.png';
import Gauge4Gray from '../images/Gauge4_Gray.png';
import Gauge4Green from '../images/Gauge4_Green.png';
import Gauge4Yellow from '../images/Gauge4_Yellow.png';
import Gauge4Red from '../images/Gauge4_Red.png';
import style from './style.css';

import _filter from 'lodash/filter';
import _some from 'lodash/some';

export default class ReportSection extends React.Component {
    static propTypes = {
        domain: PropTypes.object,
        domainId: PropTypes.number,
        enteredBy: PropTypes.array,
        reportType: PropTypes.string,
        domainName: PropTypes.string,
        pageNumber: PropTypes.number,
        totalPages: PropTypes.number,
        getReportFields: PropTypes.func,
        siteFields: PropTypes.object,
        data: PropTypes.object,
        handle: PropTypes.string,
        flowType: PropTypes.string,
        getEnteredBy: PropTypes.func,
        selectedDates: PropTypes.object,
        isAggregateReport: PropTypes.bool,
    };

    getSuffix = score => {
        const { flowType } = this.props;
        let suffix;
        if (['trs', 'owbiSchool', 'owbiDistrict', 'owbiEntity'].includes(flowType)) {
            if (score >= 3) {
                if (flowType === 'trs') {
                    suffix = 'Advancing';
                } else {
                    suffix = 'Proficient';
                }
            } else if (score >= 2 && score < 3) {
                suffix = 'Progressing';
            } else {
                suffix = 'Emerging';
            }
        } else if (['careSchool', 'careDistrict', 'careEntity'].includes(flowType)) {
            if (score < 2) {
                suffix = 'NotEstablished';
            } else if (score < 3) {
                suffix = 'Emerging';
            } else if (score < 5) {
                suffix = 'Progressing';
            } else {
                suffix = 'Advancing';
            }
        } else if (flowType !== 'trs') {
            if (score >= 5) {
                suffix = 'Advancing';
            } else if (score > 2 && score <= 4) {
                suffix = 'Progressing';
            } else {
                suffix = 'Emerging';
            }
        }
        return suffix;
    };

    getGaugeImage = score => {
        const { flowType } = this.props;

        if (['trs', 'owbiSchool', 'owbiDistrict', 'owbiEntity'].includes(flowType)) {
            if (score < 2) {
                return GaugeRed;
            }
            if (score >= 2 && score < 3) {
                return GaugeYellow;
            }
            return GaugeGreen;
        }
        if (['careSchool', 'careDistrict', 'careEntity'].includes(flowType)) {
            if (score < 2) {
                return Gauge4Gray;
            }
            if (score >= 2 && score < 3) {
                return Gauge4Red;
            }
            if (score >= 3 && score < 5) {
                return Gauge4Yellow;
            }
            return Gauge4Green;
        }
        if (score < 3) {
            return GaugeRed;
        }
        if (score >= 3 && score < 5) {
            return GaugeYellow;
        }
        return GaugeGreen;
    };

    getScoreColor = score => {
        const { flowType } = this.props;
        if (['trs', 'owbiSchool', 'owbiDistrict', 'owbiEntity'].includes(flowType)) {
            if (score < 2) {
                return style.scoreRed;
            }
            if (score >= 2 && score < 3) {
                return style.scoreYellow;
            }
            return style.scoreGreen;
        }
        if (score < 3) {
            return style.scoreRed;
        }
        if (score >= 3 && score < 5) {
            return style.scoreYellow;
        }
        return style.scoreGreen;
    };

    getScore = () => {
        const { data, handle } = this.props;
        const scoreInfo = data.totalScores.find(item => item.handle === handle);
        return scoreInfo ? scoreInfo.score : '*';
    };

    getDistrictScore = () => {
        const { data, handle } = this.props;
        const scoreInfo = data.totalScores.find(item => item.handle === handle);
        return scoreInfo ? scoreInfo.districtScore : '*';
    };

    getCategories = () => {
        const { handle } = this.props;
        const schoolFlowCategories = _filter(schoolCategories, obj => obj.flowHandle === handle);
        const districtFlowCategories = _filter(districtCategories, obj => obj.flowHandle === handle);

        return { schoolFlowCategories, districtFlowCategories };
    };

    getDataByHandle = () => {
        const { data, handle } = this.props;
        const scoreData = data.totalScores.find(item => item.handle === handle);
        const indicatorData = { school: {}, preschool: {}, district: {} };

        if (_some(scoreData.indicators, indicator => indicator.group === 'school')) {
            indicatorData.school = _filter(scoreData.indicators, indicator => indicator.group === 'school');
        }
        if (_some(scoreData.indicators, indicator => indicator.group === 'preschool')) {
            indicatorData.preschool = _filter(scoreData.indicators, indicator => indicator.group === 'preschool');
        }
        if (_some(scoreData.indicators, indicator => indicator.group === 'district')) {
            indicatorData.district = _filter(scoreData.indicators, indicator => indicator.group === 'district');
        } else {
            indicatorData.school = scoreData ? scoreData.indicators : { score: NaN, title: 'N/A' };
        }

        return indicatorData;
    };

    selectedUsersHaveDataInDomain = () => {
        const { data, enteredBy, handle } = this.props;
        const domainData = data.byDate.find(d => Object.keys(d)[0] === handle);
        if (domainData) {
            const pawnIds = domainData[handle].map(d => d.pawn_id);
            if (this.props.isAggregateReport) {
                const thereIsData = domainData[handle].length > 0;
                return thereIsData;
            }
            return pawnIds.some(id => enteredBy.includes(id));
        }
    };

    render() {
        const {
            domainId,
            domainName,
            pageNumber,
            totalPages,
            getReportFields,
            siteFields,
            flowType,
            enteredBy,
            data,
            handle,
            reportType,
        } = this.props;
        const score = this.getScore();
        const districtScore = this.getDistrictScore();

        if (domainId === 3 && flowType !== 'mhqPreschool') {
            if (this.selectedUsersHaveDataInDomain()) {
                return (
                    <Screening
                        pageNumber={pageNumber}
                        totalPages={totalPages}
                        enteredBy={enteredBy}
                        getEnteredBy={this.props.getEnteredBy}
                        domainName={domainName}
                        data={data}
                        handle={handle}
                        flowType={flowType}
                        reportType={reportType}
                        selectedDates={this.props.selectedDates}
                    />
                );
            }
        }

        if (domainId === 6 && flowType === 'mhqPreschool') {
            if (this.selectedUsersHaveDataInDomain()) {
                return (
                    <PreschoolScreening
                        pageNumber={pageNumber}
                        totalPages={totalPages}
                        enteredBy={enteredBy}
                        getEnteredBy={this.props.getEnteredBy}
                        domainName={domainName}
                        data={data}
                        handle={handle}
                        flowType={flowType}
                        reportType={reportType}
                        selectedDates={this.props.selectedDates}
                    />
                );
            }
        }

        if (domainId === 7) {
            if (this.selectedUsersHaveDataInDomain()) {
                return (
                    <Impact
                        pageNumber={pageNumber}
                        totalPages={totalPages}
                        enteredBy={enteredBy}
                        getEnteredBy={this.props.getEnteredBy}
                        domainName={domainName}
                        data={data}
                        handle={handle}
                        flowType={flowType}
                        selectedDates={this.props.selectedDates}
                    />
                );
            }
        }

        const getReportSectionByType = () => {
            if (['trs', 'trsEntity', 'trsDistrict', 'trsSchool'].includes(flowType)) {
                return (
                    <TrsReportSection
                        domainName={domainName}
                        getEnteredBy={this.props.getEnteredBy}
                        selectedDates={this.props.selectedDates}
                        siteFields={siteFields}
                        getReportFields={getReportFields}
                        domainId={domainId}
                        getGaugeImage={this.getGaugeImage}
                        score={score}
                        getScoreColor={this.getScoreColor}
                        getDataByHandle={this.getDataByHandle}
                        getCategories={this.getCategories}
                        getSuffix={this.getSuffix}
                        pageNumber={pageNumber}
                        totalPages={totalPages}
                        flowType={flowType}
                    />
                );
            }

            if (
                ['owbiSchool', 'owbiDistrict', 'owbiEntity', 'careSchool', 'careDistrict', 'careEntity'].includes(
                    flowType
                )
            ) {
                return (
                    <SinglePageReportSection
                        domainName={domainName}
                        getEnteredBy={this.props.getEnteredBy}
                        selectedDates={this.props.selectedDates}
                        siteFields={siteFields}
                        getReportFields={getReportFields}
                        domainId={domainId}
                        getGaugeImage={this.getGaugeImage}
                        score={Number.isNaN(parseFloat(score)) ? '' : score}
                        getScoreColor={this.getScoreColor}
                        getDataByHandle={this.getDataByHandle}
                        getCategories={this.getCategories}
                        getSuffix={this.getSuffix}
                        pageNumber={pageNumber}
                        totalPages={totalPages}
                        flowType={flowType}
                    />
                );
            }

            return (
                <>
                    <Page>
                        <div className={style.content}>
                            <div className={style.header}>
                                <div className={style.headerLogo}>
                                    <div className={style.triangle} />
                                    <div className={style.logo} />
                                </div>
                                <div className={style.headerTitle}>{domainName}</div>
                            </div>
                            <SubHeading
                                getEnteredBy={this.props.getEnteredBy}
                                selectedDates={this.props.selectedDates}
                            />
                            <div className={style.reportPage}>
                                <div style={{ marginBottom: '1em' }}>
                                    <span
                                        style={{
                                            fontSize: '18px',
                                            color: '#0b72b6',
                                            lineHeight: '1.8',
                                        }}
                                    >
                                        About {domainName}
                                    </span>
                                    <div className={style.aboutText}>
                                        <Html content={siteFields[getReportFields(domainId)]} />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className={style.footer}>
                            <div className={style.footerText}>
                                <div>
                                    This progress report was developed by the National Center for School Mental Health
                                    for the SHAPE System.
                                </div>
                                <div>
                                    Page {pageNumber} of {totalPages}
                                </div>
                            </div>
                            <div className={style.footerLogo} />
                        </div>
                    </Page>
                    <Page>
                        <div className={style.content}>
                            <div className={style.header}>
                                <div className={style.headerLogo}>
                                    <div className={style.triangle} />
                                    <div className={style.logo} />
                                </div>
                                <div className={style.headerTitle}>{domainName}</div>
                            </div>
                            <SubHeading
                                getEnteredBy={this.props.getEnteredBy}
                                selectedDates={this.props.selectedDates}
                            />
                            <div className={style.reportPage}>
                                <div className={style.overallScore}>
                                    <div
                                        className={style.gauge}
                                        style={{
                                            backgroundImage: `url(${this.getGaugeImage(score)})`,
                                        }}
                                    />
                                    <div className={style.gaugeText}>
                                        {flowType === 'mhqDistrict' && (
                                            <div>
                                                <span className={style.gaugeDescription}>
                                                    For schools in your district
                                                </span>
                                            </div>
                                        )}
                                        {flowType === 'mhqEntity' && (
                                            <div>
                                                <span className={style.gaugeDescription}>
                                                    For schools in your entity
                                                </span>
                                            </div>
                                        )}
                                        {flowType === 'mhqPreschool' && (
                                            <div>
                                                <span className={style.gaugeDescription}>
                                                    For preschools in your entity
                                                </span>
                                            </div>
                                        )}
                                        <div className={style.gaugeScore}>
                                            OVERALL COMPOSITE SCORE:{' '}
                                            <span className={this.getScoreColor(score)}>{score}</span>
                                        </div>
                                    </div>
                                </div>
                                <BarGraph
                                    metrics={this.getDataByHandle().school}
                                    categories={this.getCategories().schoolFlowCategories}
                                    flowType={flowType}
                                />
                                {['mhqDistrict', 'mhqEntity'].includes(flowType) && (
                                    <>
                                        <div className={style.overallScore}>
                                            <div
                                                className={style.gauge}
                                                style={{
                                                    backgroundImage: `url(${this.getGaugeImage(districtScore)})`,
                                                }}
                                            />
                                            <div className={style.gaugeText}>
                                                {flowType === 'mhqDistrict' && (
                                                    <div>
                                                        <span className={style.gaugeDescription}>District support</span>
                                                    </div>
                                                )}
                                                {flowType === 'mhqEntity' && (
                                                    <div>
                                                        <span className={style.gaugeDescription}>Entity support</span>
                                                    </div>
                                                )}
                                                <span className={style.gaugeScore}>
                                                    OVERALL COMPOSITE SCORE:{' '}
                                                    <span className={this.getScoreColor(districtScore)}>
                                                        {districtScore}
                                                    </span>
                                                </span>
                                            </div>
                                        </div>
                                        <BarGraph
                                            metrics={this.getDataByHandle().district}
                                            categories={this.getCategories().districtFlowCategories}
                                            flowType={flowType}
                                        />
                                    </>
                                )}
                                <div style={{ marginBottom: '1em' }}>
                                    {['trs', 'trsEntity', 'trsDistrict', 'trsSchool'].includes(flowType) && (
                                        <Html content={siteFields[getReportFields(domainId) + this.getSuffix(score)]} />
                                    )}
                                </div>
                            </div>
                        </div>
                        <div className={style.footer}>
                            <div className={style.footerText}>
                                <div>
                                    This progress report was developed by the National Center for School Mental Health
                                    for the SHAPE System.
                                </div>
                                <div>
                                    Page {pageNumber + 'b'} of {totalPages}
                                </div>
                            </div>
                            <div className={style.footerLogo} />
                        </div>
                    </Page>
                </>
            );
        };

        return getReportSectionByType();
    }
}
