import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import moment from 'moment';
import { Report } from 'cccisd-laravel-report';
import Loader from 'cccisd-loader';
import { getSavedParams } from 'js/reducers/params.js';
import { getShapeFlowData } from 'js/reducers/shape.js';
import { trsFields as trsReportFields } from 'js/reducers/siteFields/trsFields.js';
import {
    school as owbiSchoolReportFields,
    district as owbiDistrictReportFields,
    entity as owbiEntityReportFields,
} from 'js/reducers/siteFields/owbiFields.js';
import {
    careSchool as careSchoolReportFields,
    careDistrict as careDistrictReportFields,
    careEntity as careEntityReportFields,
} from 'js/reducers/siteFields/careFields.js';
import { mhqFields as mhqReportFields } from 'js/reducers/siteFields/mhqFields.js';
import { instructionFields } from 'js/reducers/siteFields/instructionFields.js';
import { preschoolFields as preschoolReportFields } from 'js/reducers/siteFields/preschoolFields.js';
import {
    TRSscoring,
    MHQscoringSchool,
    MHQscoringPreschool,
    MHQscoringDistrict,
    MHQscoringEntity,
    OWBIscoringDistrict,
    OWBIscoringSchool,
    OWBIscoringEntity,
    CAREscoringDistrict,
    CAREscoringSchool,
    CAREscoringEntity,
} from 'js/selectors/scoringData.js';
import {
    districtDomainFields,
    entityDomainFields,
    domainFields,
    preschoolDomainFields,
    owbiSchoolDomainFields,
    owbiDistrictDomainFields,
    owbiEntityDomainFields,
    careSchoolDomainFields,
    careDistrictDomainFields,
    careEntityDomainFields,
} from './domainFields.js';
import { getDomainNames } from 'js/selectors/shape.js';
import ReportSection from './ReportSection';
import StrategicPlanningGuide from './StategicPlanningGuide';
import CoverPage from './CoverPage';
import CarePrincipalReport from './CarePrincipalReport';
const Fortress = window.cccisd && window.cccisd.fortress;

import _map from 'lodash/map';
import _maxBy from 'lodash/maxBy';
import _mapValues from 'lodash/mapValues';
import _mean from 'lodash/mean';
import _find from 'lodash/find';
import _pick from 'lodash/pick';
import _filter from 'lodash/filter';
import _includes from 'lodash/includes';
import _without from 'lodash/without';
import _round from 'lodash/round';

const mapStateToProps = (state, props) => {
    return {
        mhqFields: state.app.params.mhqFields,
        trsFields: state.app.params.trsFields,
        owbiSchoolFields: state.app.params.owbiSchoolFields,
        owbiDistrictFields: state.app.params.owbiDistrictFields,
        owbiEntityFields: state.app.params.owbiEntityFields,
        careSchoolFields: state.app.params.careSchoolFields,
        careDistrictFields: state.app.params.careDistrictFields,
        careEntityFields: state.app.params.careEntityFields,
        instructionFields: state.app.params.instructionFields,
        trsFlowData: state.app.shape.trsFlowData,
        mhqFlowData: state.app.shape.mhqFlowData,
        owbiFlowData: state.app.shape.owbiFlowData,
        careFlowData: state.app.shape.careFlowData,
        preschoolFields: state.app.params.preschoolFields,
    };
};

const principalReportLength = 4;

export default connect(mapStateToProps, { getSavedParams, getShapeFlowData })(
    class ShapeReport extends React.Component {
        static propTypes = {
            reportSettings: PropTypes.object,
            domain: PropTypes.string,
            getSavedParams: PropTypes.func,
            mhqFields: PropTypes.object,
            trsFields: PropTypes.object,
            owbiSchoolFields: PropTypes.object,
            owbiDistrictFields: PropTypes.object,
            owbiEntityFields: PropTypes.object,
            careSchoolFields: PropTypes.object,
            careDistrictFields: PropTypes.object,
            careEntityFields: PropTypes.object,
            instructionFields: PropTypes.object,
            spgPages: PropTypes.array,
            flows: PropTypes.array,
            getShapeFlowData: PropTypes.func,
            mhqFlowData: PropTypes.object,
            trsFlowData: PropTypes.object,
            owbiFlowData: PropTypes.object,
            careFlowData: PropTypes.object,
            flowType: PropTypes.string,
            showSummaryReport: PropTypes.bool,
            groupName: PropTypes.string,
            groupId: PropTypes.number,
            isAggregateReport: PropTypes.bool,
            totalChildGroups: PropTypes.number,
            pageTitle: PropTypes.string,
            childGroup: PropTypes.string,
            preschoolFields: PropTypes.object,
        };

        static defaultProps = {
            reportSettings: {
                domain: [],
                enteredBy: [],
                reportType: 'Average',
                timePeriods: ['01/2019-01/2020'],
            },
        };

        state = {
            loading: true,
        };

        componentDidMount = async () => {
            const { flowType } = this.props;
            if (
                flowType === 'trs' ||
                flowType === 'trsSchool' ||
                flowType === 'trsDistrict' ||
                flowType === 'trsEntity'
            ) {
                if (!this.props.trsFields) {
                    await this.props.getSavedParams(trsReportFields, flowType);
                }
            }
            if (flowType === 'mhqSchool' || flowType === 'mhqDistrict' || flowType === 'mhqEntity') {
                if (!this.props.mhqFields) {
                    await this.props.getSavedParams(mhqReportFields, 'mhq');
                }
            }

            if (flowType === 'owbiSchool') {
                if (!this.props.owbiSchoolFields) {
                    await this.props.getSavedParams(owbiSchoolReportFields, 'owbiSchool');
                }
            }
            if (flowType === 'owbiDistrict') {
                if (!this.props.owbiDistrictFields) {
                    await this.props.getSavedParams(owbiDistrictReportFields, 'owbiDistrict');
                }
            }
            if (flowType === 'owbiEntity') {
                if (!this.props.owbiEntityFields) {
                    await this.props.getSavedParams(owbiEntityReportFields, 'owbiEntity');
                }
            }

            if (flowType === 'careSchool') {
                if (!this.props.careSchoolFields) {
                    await this.props.getSavedParams(careSchoolReportFields, 'careSchool');
                }
            }
            if (flowType === 'careDistrict') {
                if (!this.props.careDistrictFields) {
                    await this.props.getSavedParams(careDistrictReportFields, 'careDistrict');
                }
            }
            if (flowType === 'careEntity') {
                if (!this.props.careEntityFields) {
                    await this.props.getSavedParams(careEntityReportFields, 'careEntity');
                }
            }

            if (flowType === 'mhqPreschool') {
                if (!this.props.preschoolFields) {
                    await this.props.getSavedParams(preschoolReportFields, 'preschool');
                }
            }
            this.setState({ loading: false });
        };

        componentWillUnmount = async () => {
            if (!this.props.instructionFields) {
                await this.props.getSavedParams(instructionFields, 'instructions');
            }
        };

        getDomains = () => {
            const { domain } = this.props.reportSettings;
            const { flows } = this.props;
            const domains = domain.map(id => _find(flows, { id }));

            return domains;
        };

        getDomainNames = () => {
            return this.getDomains().map(d => d.name);
        };

        getReportFields = domainId => {
            const { flowType } = this.props;

            let reportFields;
            if (
                flowType === 'trs' ||
                flowType === 'mhqSchool' ||
                flowType === 'trsSchool' ||
                flowType === 'trsDistrict' ||
                flowType === 'trsEntity'
            ) {
                reportFields = _find(domainFields, { id: domainId }).tag;
            }
            if (flowType === 'mhqPreschool') {
                reportFields = _find(preschoolDomainFields, { id: domainId }).tag;
            }
            if (flowType === 'mhqDistrict') {
                reportFields = _find(districtDomainFields, { id: domainId }).tag;
            }
            if (flowType === 'mhqEntity') {
                reportFields = _find(entityDomainFields, { id: domainId }).tag;
            }
            if (flowType === 'owbiSchool') {
                reportFields = _find(owbiSchoolDomainFields, { id: domainId }).tag;
            }
            if (flowType === 'owbiDistrict') {
                reportFields = _find(owbiDistrictDomainFields, { id: domainId }).tag;
            }
            if (flowType === 'owbiEntity') {
                reportFields = _find(owbiEntityDomainFields, { id: domainId }).tag;
            }
            if (flowType === 'careSchool') {
                reportFields = _find(careSchoolDomainFields, { id: domainId }).tag;
            }
            if (flowType === 'careDistrict') {
                reportFields = _find(careDistrictDomainFields, { id: domainId }).tag;
            }
            if (flowType === 'careEntity') {
                reportFields = _find(careEntityDomainFields, { id: domainId }).tag;
            }

            return reportFields;
        };

        // Returns an array of objects; the selected domains (filtered by date)
        getReportData = () => {
            const { domain, reportType, enteredBy } = this.props.reportSettings;
            const { flowType, isAggregateReport } = this.props;
            let shapeFlowData;

            // Metrics data
            if (
                flowType === 'trs' ||
                flowType === 'trsSchool' ||
                flowType === 'trsDistrict' ||
                flowType === 'trsEntity'
            ) {
                shapeFlowData = this.props.trsFlowData;
            } else if (['owbi', 'owbiSchool', 'owbiDistrict', 'owbiEntity'].includes(flowType)) {
                shapeFlowData = this.props.owbiFlowData;
            } else if (['care', 'careSchool', 'careDistrict', 'careEntity'].includes(flowType)) {
                shapeFlowData = this.props.careFlowData;
            } else {
                shapeFlowData = this.props.mhqFlowData;
            }
            // Get Flow Data by domain name
            const selectedDomainNames = getDomainNames(domain, flowType);
            const shapeFlowDataByDomain = _pick(shapeFlowData, selectedDomainNames);

            // Sorts data by reporting period
            const data = {};
            data.byDate = selectedDomainNames.map(domainName => {
                return {
                    [domainName]: shapeFlowDataByDomain[domainName].data,
                };
            });

            const dataByUser = data.byDate.map(r => {
                return _mapValues(r, value => {
                    if (!isAggregateReport) {
                        return _filter(value, v => _includes(enteredBy, v.pawn_id));
                    }
                    return value;
                });
            });

            const lastCompleted = dataByUser.map(r => {
                return _mapValues(r, value => {
                    if (!isAggregateReport) {
                        return _maxBy(value, 'flow_finished');
                    }
                    return value[0];
                });
            });

            // Object, contains all domains and their related indicators, with titles, handles, etc.
            let scoringData;
            if (flowType === 'trs') {
                scoringData = TRSscoring;
            }
            if (flowType === 'mhqDistrict') {
                scoringData = MHQscoringDistrict;
            }
            if (flowType === 'mhqSchool') {
                scoringData = MHQscoringSchool;
            }
            if (flowType === 'mhqPreschool') {
                scoringData = MHQscoringPreschool;
            }
            if (flowType === 'mhqEntity') {
                scoringData = MHQscoringEntity;
            }
            if (flowType === 'owbiDistrict') {
                scoringData = OWBIscoringDistrict;
            }
            if (flowType === 'owbiSchool') {
                scoringData = OWBIscoringSchool;
            }
            if (flowType === 'owbiEntity') {
                scoringData = OWBIscoringEntity;
            }
            if (flowType === 'careDistrict') {
                scoringData = CAREscoringDistrict;
            }
            if (flowType === 'careSchool') {
                scoringData = CAREscoringSchool;
            }
            if (flowType === 'careEntity') {
                scoringData = CAREscoringEntity;
            }

            const handles = data.byDate.map(item => Object.keys(item)[0]);
            // console.log('reportType', reportType);
            data.totalScores = handles.map(handle => {
                const handleData =
                    reportType === 'Average'
                        ? this.getDataByFlowHandle(dataByUser, handle)
                        : this.getDataByFlowHandle(lastCompleted, handle);

                // Get indicators collection from scoringData.js
                const indicators = _map(scoringData[handle].indicators, indicator => indicator);

                // Screening specific metrics - to handle text + numbers
                let avgIndicatorScores;
                if (
                    handle === 'district_quality_screening' ||
                    handle === 'school_quality_screening' ||
                    handle === 'entity_quality_screening' ||
                    handle === 'pre_mental_health_screening'
                ) {
                    if (reportType === 'Last Completed') {
                        let indicatorScores = [{ score: NaN, title: '' }];

                        if (handleData !== undefined) {
                            indicatorScores = indicators.map(i => {
                                const category = i.category ? i.category : '';
                                const group = i.group ? i.group : '';
                                const score = handleData[handle][handle + '_' + i.handle];
                                return {
                                    // Get scoring from handleata, add indicator tag
                                    score,
                                    title: i.title,
                                    handle: i.handle,
                                    category,
                                    group,
                                };
                            });
                        }
                        const score =
                            handleData !== undefined ? _round(parseFloat(handleData[handle][handle]), 1) : NaN;
                        const districtScoreHandle = handle === 'entity_quality_screening' ? '_entity' : '_district';
                        const districtScore =
                            handleData !== undefined
                                ? _round(parseFloat(handleData[handle][handle + districtScoreHandle]), 1)
                                : NaN;

                        return {
                            handle,
                            score,
                            districtScore,
                            indicators: indicatorScores,
                        };
                    }
                }

                // Filtered data for last completed for all reports
                if (reportType === 'Last Completed') {
                    let indicatorScores = [{ score: NaN, title: '' }];
                    if (handleData !== undefined) {
                        indicatorScores = indicators.map(i => {
                            const category = i.category ? i.category : '';
                            const group = i.group ? i.group : '';
                            const score = _round(parseFloat(handleData[handle][handle + '_' + i.handle]), 1);
                            return {
                                // Get scoring from handleata, add indicator tag
                                score,
                                title: i.title,
                                handle: i.handle,
                                category,
                                group,
                            };
                        });
                    }
                    const score = handleData !== undefined ? _round(parseFloat(handleData[handle][handle]), 1) : NaN;
                    const districtScoreTag = flowType === 'mhqEntity' ? '_entity' : '_district';
                    const districtScore =
                        handleData !== undefined
                            ? _round(parseFloat(handleData[handle][handle + districtScoreTag]), 1)
                            : NaN;
                    /*
                     *   district_quality_impact additional reporting score
                     *
                     */
                    if (handle === 'district_quality_impact' || handle === 'entity_quality_impact') {
                        const impactScoreTag =
                            handle === 'district_quality_impact'
                                ? 'district_quality_impact_schools_in_district'
                                : 'entity_quality_impact_schools_in_entity';
                        const impactScore =
                            handleData !== undefined
                                ? _round(parseFloat(handleData[handle][impactScoreTag], 10), 1)
                                : NaN;
                        const impactDistrictScoreTag =
                            handle === 'district_quality_impact'
                                ? 'district_quality_impact_District'
                                : 'entity_quality_impact_entity';
                        const impactDistrictScore =
                            handleData !== undefined
                                ? _round(parseFloat(handleData[handle][impactDistrictScoreTag], 10), 1)
                                : NaN;
                        const impactDistrictReportingScoreTag =
                            handle === 'district_quality_impact' ? 'district_quality_impact' : 'entity_quality_impact';
                        const impactDistrictReportingScore =
                            handleData !== undefined
                                ? _round(parseFloat(handleData[handle][impactDistrictReportingScoreTag], 10), 1)
                                : NaN;
                        return {
                            handle,
                            score: impactScore,
                            districtScore: impactDistrictScore,
                            districtReportingScore: impactDistrictReportingScore,
                            indicators: indicatorScores,
                        };
                    }
                    return {
                        handle,
                        score,
                        districtScore,
                        indicators: indicatorScores,
                    };
                }

                /* Average scores from the same pawnId */
                const averageScoresByUser = allScores => {
                    let avgScoresOutput = [];
                    let filteredScores = allScores.filter(x => {
                        return x.score || x.score === 0;
                    });
                    filteredScores.forEach(item => {
                        const existing = avgScoresOutput.filter(user => user.id === item.id);
                        if (existing.length) {
                            const existingIndex = avgScoresOutput.indexOf(existing[0]);
                            avgScoresOutput[existingIndex].score = _mean([
                                avgScoresOutput[existingIndex].score,
                                item.score,
                            ]);
                        } else {
                            avgScoresOutput.push(item);
                        }
                    });
                    return avgScoresOutput.map(scores => scores.score);
                };

                // Else return Average of all scores.
                avgIndicatorScores = indicators.map(i => {
                    // Return array of indicator scores across relevant flow completions
                    //  console.log('handleData', handleData);
                    // console.log('handle', handle);
                    // console.log('i', i);
                    // console.log('indicators', indicators);
                    let avg = handleData[handle]
                        .map(pawnData => {
                            return {
                                id: pawnData.pawn_id,
                                score: parseFloat(pawnData[handle + '_' + i.handle], 10),
                            };
                        })
                        .filter(x => typeof x.score === 'number');
                    avg = averageScoresByUser(avg);
                    // Remove NaN values and get average of indicator scores
                    if (avg && avg.length > 0) {
                        avg = _without(avg, NaN);
                        avg = _round(parseFloat(_mean(avg)), 1);
                    }

                    const category = i.category ? i.category : '';
                    const group = i.group ? i.group : '';
                    // console.log(avg);
                    return {
                        score: avg,
                        title: i.title,
                        handle: i.handle,
                        category,
                        group,
                    };
                });

                let avgScores = handleData[handle]
                    .map(pawnData => {
                        return {
                            id: pawnData.pawn_id,
                            score: parseFloat(pawnData[handle], 10),
                        };
                    })
                    .filter(x => typeof x.score === 'number');

                let avgDistrictScores = handleData[handle]
                    .map(pawnData => {
                        return {
                            id: pawnData.pawn_id,
                            score:
                                flowType === 'mhqEntity'
                                    ? parseFloat(pawnData[handle + '_entity'], 10)
                                    : parseFloat(pawnData[handle + '_district'], 10),
                        };
                    })
                    .filter(x => typeof x.score === 'number');

                /*
                 *   district_quality_impact and entity_quality_impact additional reporting score
                 *
                 */
                let districtReportingScore;
                if (handle === 'district_quality_impact' || handle === 'entity_quality_impact') {
                    // Average Values
                    avgDistrictScores = handleData[handle]
                        .map(pawnData => {
                            return {
                                id: pawnData.pawn_id,
                                score:
                                    handle === 'entity_quality_impact'
                                        ? parseFloat(pawnData[handle + '_entity'], 10)
                                        : parseFloat(pawnData[handle + '_District'], 10),
                            };
                        })
                        .filter(x => typeof x.score === 'number');
                    // Impact Composite Score
                    avgScores = handleData[handle]
                        .map(pawnData => {
                            return {
                                id: pawnData.pawn_id,
                                score:
                                    handle === 'entity_quality_impact'
                                        ? parseFloat(pawnData[handle + '_schools_in_entity'], 10)
                                        : parseFloat(pawnData[handle + '_schools_in_district'], 10),
                            };
                        })
                        .filter(x => typeof x.score === 'number');
                    // District Reporting Score
                    let avgDistrictReportingScores = handleData[handle]
                        .map(pawnData => {
                            return {
                                id: pawnData.pawn_id,
                                score: parseFloat(pawnData[handle], 10),
                            };
                        })
                        .filter(x => typeof x.score === 'number');
                    avgDistrictReportingScores = averageScoresByUser(avgDistrictReportingScores);
                    districtReportingScore = _round(_mean(avgDistrictReportingScores), 1);
                }

                avgDistrictScores = averageScoresByUser(avgDistrictScores);
                avgScores = averageScoresByUser(avgScores);
                const score = _round(_mean(avgScores), 1);
                const districtScore = _round(_mean(avgDistrictScores), 1);

                return {
                    handle,
                    score,
                    districtScore,
                    districtReportingScore,
                    indicators: avgIndicatorScores,
                };
            });

            return data;
        };

        // Object, contains flow data per flow handle, to be passed into
        // the appropriate ReportSection
        getDataByFlowHandle = (data, handle) => {
            return _filter(data, d => d[handle]).shift();
        };

        getEnteredBy = () => {
            const {
                reportSettings: { enteredBy },
                childGroup,
                isAggregateReport,
                totalChildGroups,
            } = this.props;
            if (isAggregateReport) {
                let groupWord;
                if (childGroup === 'school') {
                    totalChildGroups === 1 ? (groupWord = ' School') : (groupWord = ' Schools');
                } else {
                    totalChildGroups === 1 ? (groupWord = ' District') : (groupWord = ' Districts');
                }
                return totalChildGroups + groupWord;
            }

            if (enteredBy.length === 1) {
                return `${enteredBy.length} User`;
            }

            return `${enteredBy.length} Users`;
        };

        getCoverPageGroupName = () => {
            if (Fortress.user.acting.group) {
                if (this.props.groupName) {
                    return this.props.groupName;
                }
                return Fortress.user.acting.group.label;
            }
            return 'Individual Account';
        };

        getDatesSelected = () => {
            const {
                reportSettings: { timePeriods },
            } = this.props;

            const startDates = timePeriods.map(date => date.slice(0, 7));
            const endDates = timePeriods.map(date => date.slice(8));

            const formatDates = dates => {
                return dates.map(date => {
                    const month = date.slice(0, 2);
                    const year = date.slice(3);
                    return year + '-' + month;
                });
            };

            const formattedStartDates = formatDates(startDates);
            const formattedEndDates = formatDates(endDates);

            const getEarliestDate = dates => {
                const format = 'YYYY-MM';
                let minDate = moment(dates[0], format);
                let minDateKey = 0;
                minDateKey;
                for (let i = 1; i < dates.length; i++) {
                    let date = moment(dates[i], format);
                    if (minDate > date) {
                        minDate = date;
                        minDateKey = i;
                    }
                }
                return minDate;
            };

            const getLatestDate = dates => {
                const format = 'YYYY-MM';
                let maxDate = moment(dates[0], format);
                let maxDateKey = 0;
                maxDateKey;
                for (let i = 1; i < dates.length; i++) {
                    let date = moment(dates[i], format);
                    if (maxDate < date) {
                        maxDate = date;
                        maxDateKey = i;
                    }
                }
                return maxDate;
            };

            return {
                startDate: getEarliestDate(formattedStartDates),
                endDate: getLatestDate(formattedEndDates),
            };
        };

        getReportTitle = () => {
            const { flowType } = this.props;
            if (flowType === 'trs') {
                return 'Trauma Responsiveness';
            }
            if (['owbi', 'owbiSchool', 'owbiDistrict', 'owbiEntity'].includes(flowType)) {
                return 'Organizational Well-Being Inventory for Schools (OWBI-S)';
            }
            if (['careSchool'].includes(flowType)) {
                return 'Culturally Responsive, Anti-Racist, and Equitable (CARE)';
            }
            return 'Mental Health Quality';
        };

        getSiteFields = () => {
            const {
                flowType,
                preschoolFields,
                trsFields,
                mhqFields,
                owbiSchoolFields,
                owbiDistrictFields,
                owbiEntityFields,
                careSchoolFields,
                careDistrictFields,
                careEntityFields,
            } = this.props;

            if (['trs', 'trsEntity', 'trsDistrict', 'trsSchool'].includes(flowType)) {
                return trsFields;
            }
            if (flowType === 'mhqPreschool') {
                return preschoolFields;
            }
            if (flowType === 'owbiSchool') {
                return owbiSchoolFields;
            }
            if (flowType === 'owbiDistrict') {
                return owbiDistrictFields;
            }
            if (flowType === 'owbiEntity') {
                return owbiEntityFields;
            }
            if (flowType === 'careSchool') {
                return careSchoolFields;
            }
            if (flowType === 'careDistrict') {
                return careDistrictFields;
            }
            if (flowType === 'careEntity') {
                return careEntityFields;
            }

            return mhqFields;
        };

        getSpgPages = numDomainsSelected => {
            const { flowType } = this.props;
            if (['owbiSchool', 'owbiDistrict', 'owbiEntity'].includes(flowType)) {
                return [1, 2, 3, 4, 5];
            }

            if (['careSchool', 'careDistrict', 'careEntity'].includes(flowType)) {
                return [1, 2, 3, 4, 5, 6];
            }

            return [1, 2, 3, 4, 5];
        };

        getSpgPageNumbers = numDomainsSelected => {
            let pageNumber = numDomainsSelected + 1;
            if (this.props.showSummaryReport) {
                pageNumber += 1;
            }
            if (['careSchool', 'careDistrict', 'careEntity'].includes(this.props.flowType)) {
                pageNumber += principalReportLength;
            }

            return pageNumber;
        };

        render() {
            const { domain, enteredBy, reportType } = this.props.reportSettings;
            const { spgPages, flowType } = this.props;
            let reportLength;
            let numDomainsSelected;
            let reportData;

            if (domain) {
                numDomainsSelected = this.getDomains().length;
                const spgLength = spgPages.length;
                reportLength = numDomainsSelected + spgLength + (this.props.showSummaryReport ? 1 : 0);

                // CARE adds 3 Principal Report pages
                if (['careSchool', 'careDistrict', 'careEntity'].includes(flowType)) {
                    reportLength += principalReportLength;
                }
                reportData = this.getReportData();
            }
            if (this.state.loading) {
                return <Loader loading />;
            }

            return (
                <Report showPagination downloadFilename="Shape_Report" width="1">
                    {this.props.showSummaryReport && (
                        <CoverPage
                            reportLength={reportLength}
                            title={this.getReportTitle()}
                            getEnteredBy={this.getEnteredBy}
                            domains={domain}
                            groupName={this.getCoverPageGroupName()}
                            data={reportData}
                            flowType={flowType}
                            pageTitle={this.props.pageTitle}
                            isAggregateReport={this.props.isAggregateReport}
                            selectedDates={this.getDatesSelected()}
                        />
                    )}
                    {domain &&
                        this.getDomains().map((d, index) => {
                            return (
                                <ReportSection
                                    key={d.name}
                                    domain={d}
                                    domainName={d.name}
                                    domainId={domain[index]}
                                    enteredBy={enteredBy}
                                    getEnteredBy={this.getEnteredBy}
                                    reportType={reportType}
                                    pageNumber={index + (this.props.showSummaryReport ? 2 : 1)}
                                    totalPages={reportLength}
                                    getReportFields={this.getReportFields}
                                    siteFields={this.getSiteFields()}
                                    handle={d.handle}
                                    data={reportData}
                                    flowType={this.props.flowType}
                                    selectedDates={this.getDatesSelected()}
                                    isAggregateReport={this.props.isAggregateReport}
                                />
                            );
                        })}
                    {['careSchool'].includes(flowType) && numDomainsSelected >= 1 && (
                        <CarePrincipalReport
                            pageNumber={numDomainsSelected + (this.props.showSummaryReport ? 2 : 1)}
                            totalPages={reportLength}
                            totalScores={reportData.totalScores}
                            flowType={flowType}
                            getEnteredBy={this.getEnteredBy}
                            selectedDates={this.getDatesSelected()}
                            siteFields={this.getSiteFields()}
                        />
                    )}
                    {spgPages && (
                        <StrategicPlanningGuide
                            siteFields={this.getSiteFields()}
                            spgPages={this.getSpgPages(numDomainsSelected)}
                            pageNumber={this.getSpgPageNumbers(numDomainsSelected)}
                            totalPages={reportLength}
                            flowType={flowType}
                        />
                    )}
                </Report>
            );
        }
    }
);
